import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/button/Button';
import Styles from 'components/newBroadCast/BroadCastType/ChooseBroadCastType.module.scss';
import micImage from 'assets/svg/mic on_white.svg';
import videoImage from 'assets/svg/video on_white.svg';
import { BROADCAST_STATUS } from 'store/constants';

interface ChooseBroadCastTypeProps {
  cssClass?: string;
  broadCast: Function;
  navigate: Function;
  selectedType: string;
}

const ChooseBroadCastType: FunctionComponent<ChooseBroadCastTypeProps> = (props: ChooseBroadCastTypeProps) => {
  const { cssClass, broadCast, navigate, selectedType } = props;
  const [broadCastType, setBroadCastType] = useState(selectedType || '');
  const [error, setError] = useState(false);

  const handleNavigate = () => {
    if (!broadCastType) {
      setError(true);
    } else {
      navigate(BROADCAST_STATUS.SUBSCRIPTION);
    }
  };

  const selectBroadCastType = (type: string) => {
    setBroadCastType(type);
    setError(false);
    broadCast({
      ui_mode: type === 'Video' ? 'Video' : 'none',
      streamType: type,
    });
  };
  return (
    <div className={Styles.broadCastType}>
      <h4 className="fontTwenty font-weight-600 text-center w-100">Choose broadcasting type</h4>
      <div className={`d-flex justify-content-center column-gap-30 mt-30 ${cssClass}`}>
        <div
          onClick={() => selectBroadCastType('Audio')}
          onKeyPress={() => selectBroadCastType('Audio')}
          role="button"
          tabIndex={0}
          className={`${broadCastType === 'Audio' || selectedType === 'Audio' ? Styles.yellowBorder : ''} ${
            Styles.outerBorder
          }`}
        >
          <div className={`d-flex justify-content-center align-items-center ${Styles.micWrapper}`}>
            <img src={micImage} alt="mic" className={Styles.micImage} />
          </div>
          <p className="fontSixteen text-black poppins-regular text-center mb-0 pt-3">
            Audio
            <br />
            Microphone
          </p>
        </div>
        <div
          onClick={() => selectBroadCastType('Video')}
          onKeyPress={() => selectBroadCastType('Video')}
          role="button"
          tabIndex={0}
          className={`${broadCastType === 'Video' || selectedType === 'Video' ? Styles.blueBorder : ''} ${
            Styles.outerBorder
          }`}
        >
          <div
            className={`d-flex justify-content-center align-items-center ${Styles.micWrapper} ${Styles.videoWrapper}`}
          >
            <img src={videoImage} alt="video" className={Styles.videoImage} />
          </div>
          <p className="fontSixteen text-black text-center mb-0 pt-3">Video </p>
        </div>
      </div>
      <div className={`w-100 text-center ${Styles.nextButtonWrapper}`}>
        <Button
          cssClass={`${broadCastType ? '' : 'disabledBtn'} ${
            error ? `border-danger ${Styles.nextButton}` : Styles.nextButton
          }`}
          label="Next"
          clickHandler={() => handleNavigate()}
          icon
        />
        {error && (
          <p className="mb-0 fontFourteen text-danger mt-2">
            Please select audio microphone or
            <br />
            video to continue.
          </p>
        )}
      </div>
      <div className={Styles.videoGuide}>
        <Link to="/" className="fontFourteen text-uppercase text-decoration-none font-weight-bolder text-center">
          Watch Video Guide
        </Link>
      </div>
    </div>
  );
};
export default ChooseBroadCastType;
