import { fromJS } from 'immutable';

const initialState: any = fromJS({});

export default function generalReducer(state = initialState, action: any) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}
