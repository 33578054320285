import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';

const PrivateRoute = ({ component, path }: any) => {
  const user = getItemFromLocalStorage('user') || {};
  const broadcast = getItemFromLocalStorage('broadCast');
  if (!user.token) {
    localStorage.removeItem('user');
    return <Redirect to={{ pathname: '/login' }} />;
  }
  if (!broadcast && path === '/audiobroadcast') {
    return <Redirect to={{ pathname: '/' }} />;
  }
  if (user.token) {
    return <Route path={path} exact component={component} />;
  }
  return <Redirect to={{ pathname: '/login' }} />;
};

export default PrivateRoute;
