import Login from 'views/login';
import TermAndConditions from 'views/termAndConditions/TermAndConditions';
import Register from 'views/register';
import ForgetPassword from 'views/forgetPassword/ForgetPassword';
import RestorePassword from 'views/forgetPassword/RestorePassword';

export default [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/registration',
    component: Register,
  },
  {
    path: '/forgotPassword',
    component: ForgetPassword,
  },
  {
    path: '/termandservices',
    component: TermAndConditions,
  },
  {
    path: '/restorePassword',
    component: RestorePassword,
  },
  {
    path: '/restore-password/restore/',
    component: RestorePassword,
  },
];
