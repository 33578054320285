import axios, { AxiosRequestConfig } from 'axios';
import parseError from 'utils/ErrorParse';

export const BASE_URL = 'http://localhost:3001';

function withAPIKeys(requestConfig?: AxiosRequestConfig) {
  const token = localStorage.getItem('accessToken');
  if (token) {
    return {
      ...requestConfig,
      headers: {
        ...requestConfig?.headers,
        Authorization: `JWT ${token}` ?? 'A',
        'Content-Type': 'application/json',
      },
    };
  }
  return {
    ...requestConfig,
    headers: {
      ...requestConfig?.headers,
      'Content-Type': 'application/json',
    },
  };
}

function withMultipartAPIKeys(requestConfig?: AxiosRequestConfig) {
  const token = localStorage.getItem('accessToken');

  if (token) {
    return {
      ...requestConfig,
      headers: {
        ...requestConfig?.headers,
        Authorization: `JWT ${token}` ?? 'A',
        'Content-Type': 'multipart/form-data',
      },
    };
  }
  return {
    ...requestConfig,
    headers: {
      ...requestConfig?.headers,
      'Content-Type': 'multipart/form-data',
    },
  };
}

const addPageNumber = (url: string, params: any) => {
  let str = '';
  if (params.user_id) {
    str = `${params.user_id}/`;
    return `${url}${str}`;
  }
  if (params.checkEmail) {
    str = `email=${params.checkEmail}`;
  }
  if (params.page) {
    str = `page=${params.page}`;
  }
  if (params.limit) {
    str = `${str}${str ? '&' : ''}limit=${params.limit}`;
  }
  return str ? `${url}?${str}` : url;
};

export const apiCall = {
  get: (payload: requestType) =>
    axios
      .get(addPageNumber(payload.url, payload.params), withAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  post: (payload: requestType) =>
    axios
      .post(addPageNumber(payload.url, payload.params), payload.params, withAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  put: (payload: requestType) =>
    axios
      .put(addPageNumber(payload.url, payload.params), payload.params, withAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  patch: (payload: requestType) =>
    axios
      .patch(addPageNumber(payload.url, payload.params), payload.params, withAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  postFormData: (payload: requestType) =>
    axios
      .post(payload.url, payload.params, withMultipartAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
};

export interface requestType {
  url: string;
  isAuthToken: boolean;
  params: any;
}
