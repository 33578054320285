import React, { FunctionComponent, useEffect, useState } from 'react';
import Styles from 'components/newBroadCast/broadCastDetails/BroadCastDetails.module.scss';
import InputField from 'components/textField/InputField';
import editBlueImage from 'assets/svg/edit_blue.svg';
import shareBlueImage from 'assets/svg/share link circle_blue.svg';
import SelectBox from 'components/selectBox/SelectBox';
import Button from 'components/button/Button';
import BackButton from 'components/button/backButton/BackButton';
import { BroadCastChannel } from 'modules/types/types';
import { BROADCAST_STATUS } from 'store/constants';

interface BroadCastDetailsProps {
  navigate: Function;
  broadcast: BroadCastChannel;
  handleStartBroadCast: Function;
  loading: boolean;
}
const BroadCastDetails: FunctionComponent<BroadCastDetailsProps> = (props: BroadCastDetailsProps) => {
  const { navigate, broadcast, handleStartBroadCast, loading } = props;
  const [title, setTitle] = useState('');
  const [range, setRange] = useState<any>();
  const [channelError, setChannelError] = useState(false);
  const [rangeError, setRangeError] = useState(false);
  const options = [
    { value: 'Public (unlimited)', label: 'Public (unlimited)' },
    { value: '100 m', label: '100 m' },
    { value: '1.0 km', label: '1.0 km' },
    { value: '10 km', label: '10 km' },
  ];

  const handleBack = () => {
    if (broadcast.plan === 'free') {
      navigate(BROADCAST_STATUS.SUBSCRIPTION);
    } else if (broadcast.plan === 'entryFee') {
      navigate(BROADCAST_STATUS.SUBSCRIPTION_CHARGES);
    }
  };

  const handleEdit = () => {
    navigate(BROADCAST_STATUS.SUBSCRIPTION);
  };

  const handleStart = () => {
    if (title.length === 0) {
      setChannelError(true);
    }
    if (range === undefined) {
      setRangeError(true);
    }
    if (title.length > 0 && range !== undefined) {
      handleStartBroadCast({ title, range });
    }
    return '';
  };
  useEffect(() => {
    if (title.length > 0) {
      setChannelError(false);
    }
  }, [title]);
  useEffect(() => {
    if (range !== undefined) {
      setRangeError(false);
    }
  }, [range]);
  return (
    <div
      className={`${broadcast.plan === 'free' ? Styles.broadCastDetailsFreeWrapper : Styles.broadCastDetailsWrapper}`}
    >
      <h4 className="w-100 text-center poppins-bold font-weight-bolder fontTwenty text-black">Broadcast Details</h4>
      <InputField
        placeholder="Channel name"
        type="text"
        cssClass={`poppins-regular ${Styles.inputStyle}`}
        handleChange={(e: string) => setTitle(e)}
        errorMessage={`${channelError ? 'Please select channel name' : ''}`}
      />
      <SelectBox placeholder="Area Limit" handleChange={setRange} value={range} options={options} />
      {rangeError && <p className={Styles.errorMessage}>Please select range</p>}
      <div className="d-flex justify-content-between align-items-center pt-40 mb-3">
        <p className="poppins-bold fontSixteen text-black mb-0">Subscription</p>
        <button
          type="button"
          className={`${Styles.edit} d-inline-flex align-items-center column-gap-10
          poppins-bold fontSixteen text-blue text-decoration-none`}
          onClick={handleEdit}
        >
          Edit
          <img src={editBlueImage} alt="edit icon" className={Styles.editIcon} />
        </button>
      </div>
      {broadcast.plan !== 'free' && (
        <>
          <p className="fontFourteen poppins-bold text-warning">Entry Fee</p>
          <p className="fontFourteen poppins-bold text-warning">Charge a fee to join your broadcast ($1 minimum)</p>
          <p className="fontSixteen poppins-bold text-warning">FEE - $2.00</p>
        </>
      )}
      <hr />
      <div className="justify-content-center mt-30 d-flex align-items-center column-gap-10">
        <p className="mb-0 fontFourteen text-blue poppins-bold">Share Broadcast Link</p>
        <img src={shareBlueImage} alt="share icon" className={Styles.editIcon} />
      </div>
      <div className={Styles.btnTopMargin}>
        <Button showLoading={loading} label="START BROADCAST" clickHandler={handleStart} />
        <div className="mt-3">
          <BackButton handleBack={() => handleBack()} label="Back" iconClass="fas fa-angle-left" />
        </div>
      </div>
    </div>
  );
};
export default BroadCastDetails;
