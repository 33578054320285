import React, { FunctionComponent } from 'react';
import Styles from 'components/broadCastCards/BroadCastCards.module.scss';
import { BroadCast, User } from 'modules/user/types';
import { useHistory } from 'react-router';
import { getDateTime, getDistance } from 'helpers/utils';
import userIcon from 'assets/images/user.png';

interface NearbyCardProps {
  item: BroadCast;
  location: any;
  user: User;
}

const BroadCastScheduleCards: FunctionComponent<NearbyCardProps> = (props) => {
  const { item, location, user } = props;
  const history = useHistory();
  const scheduleBroadCastDetail = () => {
    history.push({ pathname: '/broadcastdetails', state: { item } });
  };
  const createdTime = (time: any) => {
    const startTime = new Date(time);
    return getDateTime(startTime);
  };
  return (
    <>
      <div
        className={`${Styles.cardWrapper} ${Styles.cardWrapperSchedule}`}
        onClick={scheduleBroadCastDetail}
        onKeyPress={scheduleBroadCastDetail}
        role="button"
        tabIndex={0}
      >
        <p className="w-100 text-center mb-0 fontThirteen text-black font-weight-bold">{item && item.room_id}</p>
        <span
          className={`d-inline-block w-100 text-center fontThirteen text-black 
        ${Styles.itemDescription} ${Styles.itemDescriptionSchedule}`}
        >
          {user.fullname === item.admin?.name ? '-- You --' : item.admin?.name}
        </span>
        <div className="w-100 text-center my-3">
          <img src={(item && item.admin?.avatar) || userIcon} alt="pic" className={`${Styles.scheduleCardImage}`} />
        </div>
        <div className={Styles.scheduleCardFooter}>
          <div className="d-flex align-items-center justify-content-center column-gap-5 w-100">
            <i className="fa fa-location-arrow text-warning fontThirteen" />
            <span className="mb-0 fontEleven text-black text-uppercase">
              {item?.location
                ? `${getDistance(
                    location.latitude,
                    location.longitude,
                    item.location.latitude,
                    item.location.longitude,
                  )}Km`
                : `${0}Km`}
            </span>
          </div>
          <div className={`d-flex align-items-center justify-content-center column-gap-5 mt-1 ${Styles.timeSection}`}>
            <i className="fa fa-clock text-warning fontThirteen" />
            <span className="mb-0 fontEleven text-black text-uppercase">{createdTime(item.startTime)}</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default BroadCastScheduleCards;
