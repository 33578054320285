// import Home from 'views/home/Home';
import BroadCasting from 'views/broadCasting';
// import PrivacyPolicy from 'views/privacyPolicy/PrivacyPolicy';
import MenuLanding from 'views/profile/landing';
import AudioBroadCast from 'views/audioBroadCast/index';
import BroadCastDetails from 'views/broadCastDetails/index';
import Jitsi from '../components/Jitsi/Jitsi';

export default [
  {
    path: '/broadcasting',
    component: BroadCasting,
  },
  {
    path: '/broadcastdetails',
    component: BroadCastDetails,
  },
  {
    path: '/audiobroadcast',
    component: AudioBroadCast,
  },
  {
    path: '/explainervideo',
    component: MenuLanding,
  },
  {
    path: '/share',
    component: MenuLanding,
  },
  {
    path: '/subscription',
    component: MenuLanding,
  },
  {
    path: '/balance',
    component: MenuLanding,
  },
  {
    path: '/profile',
    component: MenuLanding,
  },
  {
    path: '/dateandtime',
    component: MenuLanding,
  },
  {
    path: '/notifications',
    component: MenuLanding,
  },
  {
    path: '/feedback',
    component: MenuLanding,
  },
  {
    path: '/termsandconditions',
    component: MenuLanding,
  },
  {
    path: '/privacypolicy',
    component: MenuLanding,
  },
  {
    path: '/jitsi',
    component: Jitsi,
  },
];
