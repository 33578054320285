import React, { FunctionComponent } from 'react';
import Styles from 'components/newBroadCast/entryFee/EntryFee.module.scss';
import ticketWhiteImage from 'assets/svg/ticket_white.svg';
import Button from 'components/button/Button';
import { BROADCAST_STATUS } from 'store/constants';
import DashboardLink from 'components/DashboardLink/DashboardLink';

interface EntryFeeProps {
  navigate: Function;
}

const EntryFee: FunctionComponent<EntryFeeProps> = (props: EntryFeeProps) => {
  const { navigate } = props;
  const handleJoin = () => {
    navigate(BROADCAST_STATUS.BROADCAST_DETAILS);
  };
  return (
    <div className={Styles.entryFeeWrapper}>
      <h4 className="poppins-bold font-weight-bolder fontTwenty text-black">Summer Fest</h4>
      <div className={Styles.boxBorder}>
        <div className={`d-flex justify-content-center flex-column ${Styles.box}`}>
          <h5 className={`poppins-bold text-white mb-0 ${Styles.boxTitle}`}>Entry Fee</h5>
          <div className="d-flex align-items-center justify-content-center column-gap-15">
            <img src={ticketWhiteImage} alt="ticket" />
            <p className={`text-white mb-0 poppins-bold ${Styles.boxDetails}`}>$2.00</p>
          </div>
        </div>
      </div>
      <p className="poppins-bold fontSixteen text-black mb-3">This broadcast requires an entry fee to join.</p>
      <p className="fontSixteen text-black poppins-regular">Accept the charges to join the broadcast?</p>
      <Button label="Join BroadCast" cssClass={`mt-4 ${Styles.joinBroadCastBtn}`} clickHandler={() => handleJoin()} />
      <div className="mt-3">
        <DashboardLink iconClass="fas fa-long-arrow-alt-left" />
      </div>
    </div>
  );
};

export default EntryFee;
