import {
  ILoginParams,
  IRegisterConfirmParams,
  IRegisterParams,
  IResendCode,
  IRestorePasswordParams,
} from 'modules/params/param.type';
import { User } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS } from 'store/constants';

export const loginAttempt = {
  pending: {
    type: ACTIONS.LOGIN.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.LOGIN.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.LOGIN.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const logoutSucceed = () => ({
  type: ACTIONS.AUTH_LOGOUT,
});

export const callLogout = () => ({
  type: ACTIONS.AUTH_LOGOUT_BEGIN,
});

export const authenticatedUser = (state: User) => ({
  type: ACTIONS.AUTHENTICATED_USER,
  data: state,
});

export const getLoginRequest = (state: ILoginParams) => ({
  type: ACTIONS.LOGIN_CALL,
  payload: state,
});

export const resetErrorMesage = () => ({
  type: ACTIONS.RESET_ERROR_MESSAGE,
});

export const userRegisterationBegin = (state: IRegisterParams) => ({
  type: ACTIONS.SIGNUP_CALL,
  payload: state,
});

export const registerUser = {
  pending: {
    type: ACTIONS.SIGNUP.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.SIGNUP.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SIGNUP.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const setRegistrationConfirmation = (payload: boolean) => ({
  type: ACTIONS.SET_REGISTRATION_CONFIRMATION,
  payload,
});

export const registerConfirmationBegin = (payload: IRegisterConfirmParams) => ({
  type: ACTIONS.REGISTERATION_CONFIRMATION_BEGIN,
  payload,
});

export const registerConfirmation = {
  pending: {
    type: ACTIONS.REGISTERATION_CONFIRMATION.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.REGISTERATION_CONFIRMATION.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.REGISTERATION_CONFIRMATION.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const resendRegisterationCodeBegin = (payload: IResendCode) => ({
  type: ACTIONS.REGISTRATION_CODE_RESEND_BEGIN,
  payload,
});

export const resendRegisterationCode = {
  pending: {
    type: ACTIONS.REGISTRATION_CODE_RESEND.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.REGISTRATION_CODE_RESEND.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.REGISTRATION_CODE_RESEND.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const restorePasswordBegin = (payload: IRestorePasswordParams) => ({
  type: ACTIONS.RESTORE_PASSWORD_CALL,
  payload,
});

export const restorePassword = {
  pending: (data: any) => ({
    type: ACTIONS.RESTORE_PASSWORD.PENDING,
    data,
    status: FETCH_STATUS.LOADING,
  }),
  success: (data: any) => ({
    type: ACTIONS.RESTORE_PASSWORD.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.RESTORE_PASSWORD.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const uploadProfileBegin = (payload: IRestorePasswordParams) => ({
  type: ACTIONS.UPLOAD_PROFILE_BEGIN,
  payload,
});

export const uploadProfile = {
  pending: {
    type: ACTIONS.UPLOAD_PROFILE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.UPLOAD_PROFILE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPLOAD_PROFILE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
