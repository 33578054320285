import BroadCastDetails from 'views/broadCastDetails/BroadCastDetails';
import { connect } from 'react-redux';
import { getUser } from 'store/selector/auth.selector';

const mapStateToProps = (state: any) => {
  const user: any = getUser(state);
  return { user };
};

export default connect(mapStateToProps, null)(BroadCastDetails);
