import AudioBroadCast from 'views/audioBroadCast/AudioBroadCast';
import { connect } from 'react-redux';
import { getUser } from 'store/selector/auth.selector';
import { getSelectedBroadCast } from '../../store/selector/broadCast.selector';
import { setSelectedBroadCast } from '../../store/actions/broadCast.actions';

const mapStateToProps = (state: any) => {
  const user = getUser(state);
  const selectedBroadCast = getSelectedBroadCast(state);
  return { user, selectedBroadCast };
};

const mapDispatchToProps = {
  setSelectedBroadCast,
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioBroadCast);
