import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ChatBox from 'components/chatBox/ChatBox';
import Styles from './AudioBroadCast.module.scss';
import Jitsi from '../../components/Jitsi/Jitsi';
import { User, BroadCast } from '../../modules/user/types';
import { broadCastStorage } from '../../helpers/utils';

interface IAudioBroadCast {
  user: User;
  selectedBroadCast: BroadCast;
  setSelectedBroadCast: Function;
}
const AudioBroadCast: FunctionComponent<IAudioBroadCast> = (props: IAudioBroadCast) => {
  const { user, selectedBroadCast, setSelectedBroadCast } = props;
  const history = useHistory();
  const [broadCastItem, setBroadCastItem] = useState<BroadCast>(selectedBroadCast);
  useEffect(() => {
    setBroadCastItem(selectedBroadCast);
  }, [selectedBroadCast]);
  const handleClose = () => {
    setSelectedBroadCast(null);
    broadCastStorage.clear();
    history.push('/');
  };
  return (
    <div className={Styles.bgColor}>
      <div className="d-flex">
        <div className={Styles.wrapper}>
          <Jitsi handleClose={handleClose} broadCast={broadCastItem} user={user} />
        </div>
        <ChatBox />
      </div>
    </div>
  );
};

export default AudioBroadCast;
