import React, { FunctionComponent } from 'react';
import Styles from 'components/textArea/TextArea.module.scss';

interface TextAreaProps {
  placeholder?: string;
  cssClass?: string;
}
const TextArea: FunctionComponent<TextAreaProps> = (props: TextAreaProps) => {
  const { placeholder, cssClass } = props;
  // const handleChange = (event: any) => {
  //   return event.target.value;
  // };
  return (
    <textarea
      className={`poppins-regular fontSixteen input-color bg-grey ${Styles.textArea} ${cssClass}`}
      placeholder={placeholder}
    />
  );
};
export default TextArea;
