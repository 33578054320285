import React, { FunctionComponent } from 'react';
// import Styles from 'components/button/backButton/BackButton.module.scss';

interface BackButtonProps {
  handleBack: Function;
  label: string;
  iconClass: string;
  cssClass?: string;
}

const BackButton: FunctionComponent<BackButtonProps> = (props: BackButtonProps) => {
  const { handleBack, label, iconClass, cssClass } = props;
  return (
    <div
      className="d-inline-flex align-items-center cursor-pointer"
      onClick={() => handleBack()}
      onKeyPress={() => handleBack()}
      role="button"
      tabIndex={0}
    >
      <i className={`pr-2 text-blue fontTwenty font-weight-bolder ${iconClass}`} />
      <span className={`text-blue fontSixteen poppins-bold ${cssClass}`}>{label}</span>
    </div>
  );
};
export default BackButton;
