import React, { FunctionComponent } from 'react';
import cardImage from 'assets/images/exp3.png';
import Styles from 'components/broadCastCards/BroadCastCards.module.scss';
import { BroadCast } from 'modules/user/types';
import { useHistory } from 'react-router';
import { broadCastStorage, getDistance } from '../../helpers/utils';
import { STREAM_TYPE } from '../../store/constants';

interface NearbyCardProps {
  item: BroadCast;
  selectedBroadCast: Function;
  location: any;
}

const BroadCastCards: FunctionComponent<NearbyCardProps> = (props) => {
  const { item, selectedBroadCast, location } = props;
  const history = useHistory();
  const broadCasting = () => {
    broadCastStorage.save(item);
    selectedBroadCast(item);
    if (item && item?.streamType === STREAM_TYPE.AUDIO) {
      history.push({ pathname: '/audiobroadcast' });
    }
    history.push({ pathname: '/audiobroadcast' });
  };
  return (
    <div className={Styles.cardWrapper} onClick={broadCasting} onKeyPress={broadCasting} role="button" tabIndex={0}>
      <p className="w-100 text-center mb-0 fontThirteen text-black font-weight-bold">{item && item.room_id}</p>
      <span
        className={`d-inline-block w-100 text-center fontThirteen text-black 
        ${Styles.itemDescription}`}
      >
        {item && item.admin?.name}
      </span>
      <div className="w-100 text-center my-3">
        <img src={(item && item.admin?.avatar) || cardImage} alt="card pic" className={`${Styles.cardImage}`} />
      </div>
      <div className={Styles.cardFooter}>
        <div className="d-flex align-items-center column-gap-5">
          <i className="fa fa-location-arrow text-warning fontThirteen" />
          <span className="mb-0 fontEleven text-black text-uppercase">
            {item?.location
              ? `${getDistance(
                  location.latitude,
                  location.longitude,
                  item.location.latitude,
                  item.location.longitude,
                )}Km`
              : `${0}Km`}
          </span>
        </div>
        <div className="d-flex align-items-center column-gap-5">
          <i className="fa fa-user-alt text-warning fontThirteen" />
          <span className="mb-0 fontEleven text-black">5</span>
        </div>
      </div>
    </div>
  );
};
export default BroadCastCards;
