import { IBroadCastParam, IScheduleBroadCastParam, ICreateChannel, IJoinStream } from 'modules/params/param.type';
import { BroadCast } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS } from 'store/constants';

export const createChannel = {
  pending: {
    type: ACTIONS.CREATE_BROADCAST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: BroadCast) => ({
    type: ACTIONS.CREATE_BROADCAST.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CREATE_BROADCAST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createChannelBegin = (payload: ICreateChannel, params: IBroadCastParam) => ({
  type: ACTIONS.CREATE_BROADCAST_BEGIN,
  payload,
  params,
});

export const createJoinStreamBegin = (payload: IJoinStream, params: IBroadCastParam) => ({
  type: ACTIONS.JOIN_STREAM_BEGIN,
  payload,
  params,
});

export const createScheduleBegin = (params: IScheduleBroadCastParam) => ({
  type: ACTIONS.SCHEDULE_STREAM_BEGIN,
  params,
});

export const createJoinStream = {
  pending: {
    type: ACTIONS.JOIN_STREAM.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.JOIN_STREAM.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.JOIN_STREAM.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
