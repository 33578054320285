import { combineReducers } from 'redux';
import userReducer from 'store/reducers/auth.reducers';
import generalReducer from 'store/reducers/general.reducers';
import broadCastReducer from 'store/reducers/broadCast.reducers';
import ACTIONS from 'store/constants';

const appReducer = combineReducers({
  users: userReducer,
  general: generalReducer,
  broadCast: broadCastReducer,
});

const rootReducer = (state: any, action: any) => {
  let prevState = state;
  if (action.type === ACTIONS.AUTH_LOGOUT) prevState = undefined;

  return appReducer(prevState, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
