import React, { FunctionComponent, useState } from 'react';
import api from 'store/services/auth.services';
import { checkEmail } from 'utils/FormValidation';
import Styles from 'views/profile/Profile.module.scss';
import InputField from 'components/textField/InputField';
import editBlue from 'assets/svg/edit_blue_1.svg';
import profileImage from 'assets/images/user.png';
import editWhite from 'assets/svg/edit_white_1.svg';
import Button from 'components/button/Button';
import { User } from 'modules/user/types';
import { LOADER_STATUS } from '../../store/constants';
// import { isValidFile } from '../../helpers/utils';
// import Loader from '../../components/loader/loader';

interface ProfileProps {
  user: User;
  uploadProfile?: Function;
}
const Profile: FunctionComponent<ProfileProps> = (props: ProfileProps) => {
  const { user, uploadProfile } = props;
  const [firstName, setFirstName] = useState<string>(user.fullname || '');
  const [lastName, setLastName] = useState<string>(user.fullname || '');
  const [email, setEmail] = useState<string>(user.email || '');
  const [password, setPassword] = useState<string>(user.password || '*******');
  const [image, setImage] = useState<any>(user.avatar || profileImage);
  const [emailError, setEmailError] = useState<string>('');
  const [emailExist, setEmailExist] = useState<boolean>(false);
  const [requiredField, setRequiredField] = useState<string[]>([]);
  const [isCheckLoading, setIsCheckLoading] = useState<number>(LOADER_STATUS.NA);
  const [passwordType, setPasswordType] = useState(false);

  const handleBlur = async (event: React.FormEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    const error = checkEmail(val);
    setEmailError(error);
    if (error.length === 0 && user.email !== val) {
      removeFromErrorList('email');
      setEmailError('');
      try {
        setIsCheckLoading(LOADER_STATUS.PENDING);
        await api.checkEmailExist(email).then((res: any) => {
          if (res.status === 'registered') {
            setIsCheckLoading(LOADER_STATUS.COMPLETE);
            setEmailExist(true);
            setEmailError('Email Already Exist');
          } else {
            setIsCheckLoading(LOADER_STATUS.COMPLETE);
            setEmailExist(false);
          }
        });
      } catch (err: any) {
        setIsCheckLoading(LOADER_STATUS.COMPLETE);
        console.log(err);
      }
    }
  };

  const handleUpdate = () => {
    const errors: string[] = [];
    if (firstName.length === 0) errors.push('firstName');
    if (lastName.length === 0) errors.push('lastName');
    if (password.length === 0) errors.push('password');
    if (email.length === 0) errors.push('email');
    if (errors.length > 0) {
      setRequiredField(errors);
      return;
    }
    if (emailExist) return;
    setRequiredField([]);
  };

  const removeFromErrorList = (val: string) => {
    if (!val) return;
    setRequiredField(requiredField.filter((x: string) => x.toLowerCase() !== val.toLowerCase()));
  };

  const isInError = (fieldName: string) => {
    if (requiredField.includes(fieldName)) {
      return `${fieldName} is required`;
    }
    return '';
  };

  const handleCancel = () => {
    console.log('handle Cancel');
  };
  console.log(uploadProfile && uploadProfile());
  const handleChangeImage = (file: any) => {
    // const isValid = isValidFile(file, 'image');
    // if (isValid) {
    const path: any = URL.createObjectURL(file.target.files[0]);
    setImage(path);
    const formData = new FormData();
    formData.append('uri', path);
    formData.append('type', file.target.files[0].type);
    // } else console.log('invalid Type');
  };
  return (
    <>
      <div className="borderStyle">
        <div className={Styles.imageWrapper}>
          <div className={`position-relative ${Styles.profileImageInner}`}>
            <img src={image} className={Styles.profileImage} alt="profile" />
            <span>
              <img src={editWhite} alt="edit icon" />
              {/* <div className="imageLoader"> */}
              {/*  <Loader /> */}
              {/* </div> */}
              <input onChange={(e) => handleChangeImage(e)} type="file" className={Styles.fileUpload} />
            </span>
          </div>
          <h3 className="text-capitalize">{user.fullname}</h3>
        </div>
        <div className="row mt-5">
          <div className="col-md-6">
            <div className="form-group">
              <InputField
                handleChange={setFirstName}
                type="text"
                cssClass={`poppins-regular ${Styles.inputStyle}`}
                value={firstName}
                placeholder="First Name"
              />
              <span className="border-danger">{isInError('firstName')}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <InputField
                blurHandler={handleBlur}
                handleChange={setEmail}
                type="email"
                cssClass={`poppins-regular ${Styles.inputStyle}`}
                value={email}
                placeholder="Email Address"
                showLoading={isCheckLoading === LOADER_STATUS.PENDING}
                errorMessage={emailError}
              />
              <span className="border-danger">{isInError('email')}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <InputField
                handleChange={setLastName}
                type="text"
                cssClass={`poppins-regular ${Styles.inputStyle}`}
                value={lastName}
                placeholder="Last Name"
              />
              <span className="border-danger">{isInError('lastName')}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <InputField
                handleChange={setPassword}
                handlePassword={() => setPasswordType(!passwordType)}
                type={passwordType ? 'text' : 'password'}
                cssClass={`poppins-regular ${Styles.inputStyle}`}
                value={password}
                placeholder="Password"
                icon={editBlue}
                iconClass={Styles.iconStyle}
              />
              <span className="border-danger">{isInError('password')}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.btnWrapper}>
        <Button label="Cancel" cssClass={Styles.cancelBtn} clickHandler={handleCancel} />
        <Button label="Save Changes" cssClass={`${Styles.saveBtn}`} clickHandler={handleUpdate} />
      </div>
    </>
  );
};

export default Profile;
