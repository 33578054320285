import React, { FunctionComponent } from 'react';
import Styles from './InputField.module.scss';
import Loader from '../loader/loader';

interface InputFieldProps {
  placeholder: string;
  type: string;
  value?: string;
  cssClass?: string;
  icon?: string;
  iconClass?: string;
  errorMessage?: string;
  handleChange?: Function;
  handleKeyDown?: Function;
  showLoading?: boolean;
  inputChangeHandler?: (event: React.FormEvent<HTMLInputElement>) => void;
  blurHandler?: (event: React.FormEvent<HTMLInputElement>) => void;
  handlePassword?: (event: React.FormEvent<HTMLDivElement>) => void;
  isDisable?: boolean;
}

const InputField: FunctionComponent<InputFieldProps> = (props: InputFieldProps) => {
  const {
    placeholder,
    type,
    value,
    handleChange,
    blurHandler,
    inputChangeHandler,
    handleKeyDown,
    cssClass,
    icon,
    iconClass,
    handlePassword,
    errorMessage,
    showLoading,
    isDisable,
  } = props;

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (handleChange) handleChange(event.currentTarget.value);
  };
  const mb = errorMessage ? '' : 'mb-3';
  return (
    <>
      <input
        disabled={!!isDisable}
        value={value}
        onChange={handleChange ? handleOnChange : inputChangeHandler}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (handleKeyDown) handleKeyDown(e);
        }}
        onBlur={blurHandler}
        type={type}
        placeholder={placeholder}
        className={
          icon
            ? `form-control ${Styles.iconPdRight} ${Styles.inputFiled} ${cssClass} ${mb}`
            : `form-control ${Styles.inputFiled} ${cssClass} ${mb}`
        }
      />
      {showLoading && <Loader aligned />}
      {icon && (
        <div onClick={handlePassword} onKeyPress={handlePassword} tabIndex={0} role="button">
          <img src={icon} alt="icon" className={`${Styles.iconStyling} ${iconClass}`} />
        </div>
      )}
      {errorMessage ? <p className={`mb-2 ${Styles.errorMessage}`}>{errorMessage}</p> : ''}
    </>
  );
};

export default InputField;
