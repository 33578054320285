import { call, put, all, takeEvery } from 'redux-saga/effects';
import { authActions } from 'store/actions';
import api from 'store/services/auth.services';
import ACTIONS from 'store/constants';
import {
  confirmRegistration,
  loginResponse,
  resendRegisterationCode,
  restorePasswordResponse,
} from 'modules/response/response.types';
import {
  IGetUser,
  ILoginParams,
  IRegisterConfirmParams,
  IRegisterParams,
  IRestorePasswordParams,
} from 'modules/params/param.type';
import { User } from '../../modules/user/types';
import getItemFromLocalStorage from '../../utils/getItemFromLocalStorage';

function* login(state: { type: string; payload: ILoginParams }) {
  try {
    yield put(authActions.loginAttempt.pending);
    const response: loginResponse = yield call(api.loginRequest, state.payload);
    if (response.token) {
      yield put(authActions.loginAttempt.success(response));
      yield localStorage.setItem('user', JSON.stringify(response));
      yield localStorage.setItem('accessToken', response.token);
      yield put({
        type: ACTIONS.GET_USER,
        payload: { user_id: response.userId },
      });
    }
  } catch (error: any) {
    console.log('error', error);
    yield put(authActions.loginAttempt.error(error.message));
  }
}

function* signUp(state: { type: string; payload: IRegisterParams }) {
  try {
    yield put(authActions.registerUser.pending);
    const response: IRegisterConfirmParams = yield call(api.signUpRequest, state.payload);
    if (response.email) {
      yield put(authActions.setRegistrationConfirmation(true));
    }
    const user: User = {
      email: response.email,
      username: state.payload.fullname,
    };
    yield localStorage.setItem('user', JSON.stringify(user));
    yield put(authActions.registerUser.success(user));
  } catch (error: any) {
    yield put(authActions.registerUser.error(error.message));
  }
}

function* confirmRegistrationCode(state: { type: string; payload: IRegisterConfirmParams }) {
  try {
    yield put(authActions.registerConfirmation.pending);
    const response: confirmRegistration = yield call(api.confirmRegistration, state.payload);
    const user: User = yield getItemFromLocalStorage('user');
    if (user) {
      yield (user.token = response.token);
      yield (user.uid = response.userId);
    }
    yield localStorage.setItem('user', JSON.stringify(user));
    yield localStorage.setItem('accessToken', response.token);
    if (response.token) yield put(authActions.registerConfirmation.success(user));
    yield put({
      type: ACTIONS.GET_USER,
      payload: { user_id: response.userId },
    });
  } catch (err: any) {
    yield put(authActions.registerConfirmation.error(err.message));
  }
}
function* ResendRegisterCode(state: { type: string; payload: { email: string } }) {
  try {
    yield put(authActions.resendRegisterationCode.pending);
    const response: resendRegisterationCode = yield call(api.resendRegisterationCode, state.payload);
    if (response.email) yield put(authActions.resendRegisterationCode.success(response.email));
  } catch (err: any) {
    yield put(authActions.resendRegisterationCode.error(err.message));
  }
}

function* logout() {
  yield call([localStorage, 'removeItem'], 'user');
  yield call([localStorage, 'removeItem'], 'accessToken');
  yield put(authActions.logoutSucceed());
}

function* restorePassword(state: { type: string; payload: IRestorePasswordParams }) {
  try {
    yield put(authActions.restorePassword.pending(state.payload.email));
    const response: restorePasswordResponse = yield call(api.restorePassword, state.payload);
    if (response) {
      yield put(authActions.restorePassword.success(response));
    }
  } catch (err: any) {
    yield put(authActions.restorePassword.error(err.message));
  }
}

function* getUser(state: { type: string; payload: IGetUser }) {
  try {
    const user: User = yield call(api.getUser, { user_id: state.payload.user_id });
    if (user) {
      const accessTokenUser: User = yield getItemFromLocalStorage('user');
      yield (user.token = accessTokenUser.token);
      yield localStorage.setItem('user', JSON.stringify(user));
      yield put(authActions.authenticatedUser(user));
    }
  } catch (err: any) {
    yield console.log('err', err.message);
  }
}
function* uploadProfile(state: { type: string; payload: IGetUser }) {
  try {
    const user: User = yield call(api.getUser, { user_id: state.payload.user_id });
    if (user) {
      const accessTokenUser: User = yield getItemFromLocalStorage('user');
      yield (user.token = accessTokenUser.token);
      yield localStorage.setItem('user', JSON.stringify(user));
      yield put(authActions.authenticatedUser(user));
    }
  } catch (err: any) {
    yield console.log('err', err.message);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.LOGIN_CALL, login),
    takeEvery(ACTIONS.AUTH_LOGOUT_BEGIN, logout),
    takeEvery(ACTIONS.SIGNUP_CALL, signUp),
    takeEvery(ACTIONS.REGISTERATION_CONFIRMATION_BEGIN, confirmRegistrationCode),
    takeEvery(ACTIONS.REGISTRATION_CODE_RESEND_BEGIN, ResendRegisterCode),
    takeEvery(ACTIONS.RESTORE_PASSWORD_CALL, restorePassword),
    takeEvery(ACTIONS.GET_USER, getUser),
    takeEvery(ACTIONS.UPLOAD_PROFILE_BEGIN, uploadProfile),
  ]);
}
