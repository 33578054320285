import { connect } from 'react-redux';
import { setRegistrationConfirmation, userRegisterationBegin } from 'store/actions/auth.actions';
import { getLoading, getRegistrationConfirm, loginError } from 'store/selector/auth.selector';
import Register from 'views/register/Register';

const mapDispatchToProps = {
  registrationRequest: userRegisterationBegin,
  resetRegistration: setRegistrationConfirmation,
};

const mapStateToProps = (state: any) => {
  const errorMsg = loginError(state);
  const isLoading = getLoading(state);
  const isConfirm = getRegistrationConfirm(state);
  return { isLoading, isConfirm, errorMsg };
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);
