import BroadCasting from 'views/broadCasting/broadCasting';
import { connect } from 'react-redux';
import { createChannelBegin, createJoinStreamBegin, createScheduleBegin } from 'store/actions/general.actions';
import { getSelectedBroadCast } from 'store/selector/broadCast.selector';
import { getUser } from 'store/selector/auth.selector';
import { setSelectedBroadCast } from '../../store/actions/broadCast.actions';

const mapDispatchToProps = {
  createChannelBegin,
  createJoinStream: createJoinStreamBegin,
  createSchedule: createScheduleBegin,
  setSelectedBroadCast,
};
const mapStateToProps = (state: any) => {
  const selectedBroadCast: any = getSelectedBroadCast(state);
  const user: any = getUser(state);
  return { selectedBroadCast, user };
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadCasting);
