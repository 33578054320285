import React, { FunctionComponent } from 'react';
import Styles from 'components/button/switchButton/SwitchButton.module.scss';

const SwitchButton: FunctionComponent = () => {
  console.log('handle Cancel');
  return (
    <label className={Styles.switch}>
      <input type="checkbox" />
      <span className={`${Styles.slider} ${Styles.round}`} />
    </label>
  );
};

export default SwitchButton;
