import asyncActionType from 'utils/reduxActions';

import summerImage from 'assets/svg/summer_festival.svg';

export const FILTER_ACTIONS = {
  SET_SCREEN_WIDTH: '[SOUND_BIRD]SET_SCREEN_WIDTH',
  LOGIN: asyncActionType('[SOUND_BIRD]LOGIN'),
  LOGIN_CALL: '[SOUND_BIRD]LOGIN_CALL',
  AUTHENTICATED_USER: '[SOUND_BIRD]AUTHENTICATED_USER',
  AUTH_LOGOUT_BEGIN: '[SOUND_BIRD]AUTH_LOGOUT_BEGIN',
  AUTH_LOGOUT: '[SOUND_BIRD]AUTH_LOGOUT',
  SIGNUP: asyncActionType('[SOUND_BIRD]SIGNUP'),
  SIGNUP_CALL: '[SOUND_BIRD]SIGNUP_CALL',

  SET_REGISTRATION_CONFIRMATION: '[SOUND_BIRD]SET_REGISTRATION_CONFIRMATION',

  REGISTERATION_CONFIRMATION_BEGIN: '[SOUND_BIRD]REGISTERATION_CONFIRMATION_BEGIN',
  REGISTERATION_CONFIRMATION: asyncActionType('[SOUND_BIRD]REGISTERATION_CONFIRMATION'),

  REGISTRATION_CODE_RESEND_BEGIN: '[SOUND_BIRD]REGISTRATION_CODE_RESEND_BEGIN',
  REGISTRATION_CODE_RESEND: asyncActionType('[SOUND_BIRD]REGISTRATION_CODE_RESEND'),

  RESTORE_PASSWORD_CALL: '[SOUND_BIRD]RESTORE_PASSWORD_CALL',
  RESTORE_PASSWORD: asyncActionType('[SOUND_BIRD]RESTORE_PASSWORD'),

  RESET_ERROR_MESSAGE: '[SOUND_BIRD]RESET_ERROR_MESSAGE',

  UPLOAD_PROFILE_BEGIN: '[SOUND_BIRD]UPLOAD_PROFILE_BEGIN',
  UPLOAD_PROFILE: asyncActionType('[SOUND_BIRD]UPLOAD_PROFILE'),

  SEARCH_STATION_BEGIN: '[SOUND_BIRD]SEARCH_STATION_BEGIN',
  SEARCH_STATION: asyncActionType('[SOUND_BIRD]SEARCH_STSEARCH_STATIONATION_BEGIN'),

  CREATE_BROADCAST_BEGIN: '[SOUND_BIRD]CREATE_BROADCAST_BEGIN',
  CREATE_BROADCAST: asyncActionType('[SOUND_BIRD]CREATE_BROADCAST'),
  BROADCAST_CREATED_ON_FIREBASE: '[SOUND_BIRD]BROADCAST_CREATED_ON_FIREBASE',

  JOIN_STREAM_BEGIN: '[SOUND_BIRD]JOIN_STREAM_BEGIN',
  JOIN_STREAM: asyncActionType('[SOUND_BIRD]JOIN_STREAM'),

  SCHEDULE_STREAM_BEGIN: '[SOUND_BIRD]SCHEDULE_STREAM_BEGIN',

  ACTIVE_BROADCASTS_CALL: '[SOUND_BIRD]ACTIVE_BROADCASTS_CALL',

  SET_SELECTED_BROADCAST: '[SOUND_BIRD]SET_SELECTED_BROADCAST',

  GET_USER: '[SOUND_BIRD]GET_USER',
};

export const FETCH_STATUS = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const BROADCAST_STATUS = {
  TYPE: 'TYPE',
  SUBSCRIPTION: 'SUBSCRIPTION',
  SUBSCRIPTION_CHARGES: 'SUBSCRIPTION_CHARGES',
  ENTRY_FEE: 'ENTRY_FEE',
  BROADCAST_DETAILS: 'BROADCAST_DETAILS',
  SCHEDULE_BROADCAST: 'SCHEDULE_BROADCAST',
  START_BROADCAST: 'START_BROADCAST',
};

export const STREAM_TYPE = {
  VIDEO: 'Video',
  LIVE: 'live',
  AUDIO: 'Audio',
};

export const BROADCAST_STEP = {
  CHOOSE_TYPE: 'LOADING',
  SUBSCRIPTION_PLAN: 'SUCCESS',
  ERROR: 'ERROR',
};

export default {
  ...FILTER_ACTIONS,
};

export const IMG_URL = 'https://mashghol.com/yalla_apis/public/uploads/images/';

export const LOADER_STATUS = {
  NA: -1,
  PENDING: 1,
  COMPLETE: 0,
};

export const data = [
  {
    id: Math.random(),
    image: summerImage,
    title: 'summer festival',
  },
  {
    id: Math.random(),
    image: summerImage,
    title: 'summer festival',
  },
  {
    id: Math.random(),
    image: summerImage,
    title: 'summer festival',
  },
];
