import React, { FunctionComponent, useState } from 'react';
import Styles from 'components/broadCastDetailImages/BroadCastDetailImages.module.scss';
import LightBox from 'components/lightBox/LightBox';

interface BroadCastDetailImagesProps {
  images: any;
}

const BroadCastDetailImages: FunctionComponent<BroadCastDetailImagesProps> = (props: BroadCastDetailImagesProps) => {
  const { images } = props;
  const [toggle, setToggle] = useState(false);
  const [sIndex, setSIndex] = useState(0);
  const lightBoxHandler = (state: any, index: any) => {
    setToggle(state);
    setSIndex(index);
  };
  return (
    <>
      {images.length > 0 && (
        <>
          <div
            role="button"
            tabIndex={0}
            onClick={() => lightBoxHandler(true, 0)}
            onKeyPress={() => lightBoxHandler(true, 0)}
            className={`${images.length > 2 ? Styles.bigImage : Styles.notBigImage}`}
          >
            {images[0] && <img src={images[0].image} alt="" />}
          </div>
          <div className={`${images.length > 2 ? Styles.columnImage : Styles.columnImageWrapper}`}>
            <div
              role="button"
              tabIndex={0}
              onClick={() => lightBoxHandler(true, 0)}
              onKeyPress={() => lightBoxHandler(true, 0)}
              className={Styles.smallImage}
            >
              {images[1] && <img src={images[1].image} alt="" />}
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => lightBoxHandler(true, 0)}
              onKeyPress={() => lightBoxHandler(true, 0)}
              className={Styles.smallImage}
            >
              {images[2] && <img src={images[2].image} alt="" />}
            </div>
          </div>
        </>
      )}
      <div className="lightBoxWrapper">
        <LightBox toggle={toggle} handleChange={lightBoxHandler} data={images} setSIndex={setSIndex} sIndex={sIndex} />
      </div>
    </>
  );
};
export default BroadCastDetailImages;
