import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/svg/logo_big.svg';
import Styles from 'views/register/Register.module.scss';
import appStore from 'assets/images/appStore.png';
import googlePlay from 'assets/images/googlePlay.png';

const LoginLeftSection: FunctionComponent = () => (
  <>
    <Link to="/broadcasting" className="d-block text-center">
      <img src={logo} alt="logo" className={`img-fluid ${Styles.logoImage}`} />
    </Link>
    <h3 className={`mb-0 ${Styles.tagLine}`}>Amplify your voice with SoundBird.</h3>
    <div className={`${Styles.appGoogleStore}`}>
      <a href="https://apps.apple.com/pk/app/soundbird-microphone/id1444546036" target="blank">
        <img src={appStore} alt="appStore" className={Styles.appImage} />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.soundbird" target="blank">
        <img src={googlePlay} alt="googlePlay" className={Styles.appImage} />
      </a>
    </div>
  </>
);

export default LoginLeftSection;
