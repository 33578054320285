import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Modal from '../modal/Modal';
import Styles from '../../views/register/Register.module.scss';
import InputField from '../textField/InputField';
import Button from '../button/Button';
import { getRegisterLoader, getRegistrationMessage, getResendCodeLoader } from '../../store/selector/auth.selector';
import { registerConfirmationBegin, resendRegisterationCodeBegin } from '../../store/actions/auth.actions';

interface IEmailConfirmModal {
  handleClose: Function;
  email: string;
  name: string;
  isResendLoading?: boolean;
  message?: string;
  isRegisterLoading?: boolean;
  resendCodeRequest?: Function;
  confirmRequest?: Function;
}
const EmailConfirmModal: FunctionComponent<IEmailConfirmModal> = (props: IEmailConfirmModal) => {
  const { handleClose, isResendLoading, isRegisterLoading, message, resendCodeRequest, confirmRequest, email, name } =
    props;
  const [registerCode, setRegisterCode] = useState<string>('');
  const [disabled, setDisabled] = useState(false);

  const handleCloseModal = () => handleClose();
  const handleSubmitCode = () => {
    if (confirmRequest) {
      confirmRequest({
        email,
        user_name: name || '',
        code: registerCode,
      });
      handleClose();
    }
  };
  useEffect(() => {
    if (registerCode === '') setDisabled(true);
    else setDisabled(false);
  }, [registerCode]);

  const handleResendCode = () => {
    if (resendCodeRequest) resendCodeRequest({ email });
  };

  return (
    <Modal closeDialog={handleCloseModal} type="type" cssClass={Styles.enterCodeWidth}>
      <div className="form-group">
        <InputField
          placeholder="Enter Confirmation Code"
          type="text"
          cssClass="bg-grey"
          handleChange={setRegisterCode}
          errorMessage={message}
        />
      </div>
      <div className="d-flex column-gap-15 justify-content-center">
        <Button
          showLoading={isRegisterLoading}
          label="Submit"
          cssClass={Styles.buttonWidth}
          clickHandler={handleSubmitCode}
          disabled={disabled}
        />
        <Button
          showLoading={isResendLoading}
          label="Resend"
          cssClass={Styles.buttonWidth}
          clickHandler={handleResendCode}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  const isResendLoading = getResendCodeLoader(state);
  const message = getRegistrationMessage(state);
  const isRegisterLoading = getRegisterLoader(state);

  return { isResendLoading, message, isRegisterLoading };
};
const mapDispatchToProps = {
  confirmRequest: registerConfirmationBegin,
  resendCodeRequest: resendRegisterationCodeBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmModal);
