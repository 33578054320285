import { ICreateChannel, IJoinStream } from 'modules/params/param.type';
import { apiCall } from 'store/services/service';
import { URL_JOIN_STREAM } from './URL';

export const api = {
  createChannel(loginParams: ICreateChannel) {
    console.log(loginParams);
    return {
      data: { streamId: '1sasb23' },
    };
  },
  createStream(params: IJoinStream) {
    return apiCall.post({ url: URL_JOIN_STREAM, isAuthToken: false, params });
  },
};

export default api;
