import React, { FunctionComponent } from 'react';
import Style from 'components/button/roundButton/RoundButton.module.scss';

interface RoundButtonProps {
  icon: string;
}
const RoundButton: FunctionComponent<RoundButtonProps> = (props: RoundButtonProps) => {
  const { icon } = props;
  return (
    <div className={Style.actionWrapper}>
      <img src={icon} alt="action" />
    </div>
  );
};
export default RoundButton;
