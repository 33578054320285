import {
  IForgetPasswordParams,
  IGetUser,
  ILoginParams,
  IRegisterConfirmParams,
  IRegisterParams,
  IResendCode,
  IRestorePasswordParams,
} from 'modules/params/param.type';
import { apiCall } from 'store/services/service';
import {
  URL_CONFIRM_REGISTRATION,
  URL_EMAIL_EXISTS,
  URL_FORGOT_PASSWORD,
  URL_LOGIN,
  URL_RESEND_REGISTRATION_CODE,
  URL_SIGNUP,
  URL_RESTORE_PASSWORD,
  URL_GET_USER,
} from 'store/services/URL';

export const api = {
  loginRequest(loginParams: ILoginParams) {
    return apiCall.post({ url: URL_LOGIN, isAuthToken: false, params: loginParams });
  },
  signUpRequest(user: IRegisterParams) {
    return apiCall.post({ url: URL_SIGNUP, isAuthToken: false, params: user });
  },
  checkEmailExist(checkEmail: string) {
    return apiCall.get({ url: URL_EMAIL_EXISTS, isAuthToken: false, params: { checkEmail } });
  },
  resendRegisterationCode(params: IResendCode) {
    return apiCall.post({ url: URL_RESEND_REGISTRATION_CODE, isAuthToken: false, params });
  },
  confirmRegistration(params: IRegisterConfirmParams) {
    return apiCall.post({ url: URL_CONFIRM_REGISTRATION, isAuthToken: false, params });
  },
  forgetPassword(params: IForgetPasswordParams) {
    return apiCall.post({ url: URL_FORGOT_PASSWORD, isAuthToken: false, params });
  },
  restorePassword(params: IRestorePasswordParams) {
    return apiCall.put({ url: URL_RESTORE_PASSWORD, isAuthToken: false, params });
  },
  getUser(params: IGetUser) {
    return apiCall.get({ url: URL_GET_USER, isAuthToken: true, params });
  },
};

export default api;
