import React, { FunctionComponent, useState } from 'react';
import Styles from 'views/dateAndTime/DateAndTime.module.scss';
import Button from 'components/button/Button';
import SwitchButton from 'components/button/switchButton/SwitchButton';
import arrowRight from 'assets/svg/right arrow_blue.svg';
import Modal from 'components/modal/Modal';
import TimeFormat from 'components/timeFormat/TimeFormat';
import DateFormat from 'components/dateFormat/DateFormat';

const DateAndTime: FunctionComponent = () => {
  const [showDateModal, setShowDateModal] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const handleCancel = () => {
    console.log('handle Cancel');
  };
  const handleChanges = () => {
    console.log('handle Changes');
  };
  const closeDialog = () => {
    setShowTimeModal(false);
    setShowDateModal(false);
  };

  return (
    <>
      <div className="borderStyle">
        <div className="d-flex justify-content-between">
          <div>
            <p className="mb-0 fontSixteen poppins-regular text-black">Automatic date and time</p>
            <span className="fontThirteen poppins-regular input-color">Use network-provided date and time</span>
          </div>
          <SwitchButton />
        </div>
        <div className="d-flex justify-content-between mt-60">
          <div>
            <p className="mb-0 fontSixteen poppins-regular text-black">Date Format</p>
            <span className="fontThirteen poppins-regular input-color">MM/DD/YY</span>
          </div>
          <div
            onClick={() => setShowDateModal(true)}
            onKeyPress={() => setShowDateModal(true)}
            role="button"
            tabIndex={0}
          >
            <img src={arrowRight} alt="arrow right" className={Styles.iconWidth} />
          </div>
        </div>
        <div className="d-flex justify-content-between mt-60">
          <div>
            <p className="mb-0 fontSixteen poppins-regular text-black">Time Format</p>
            <span className="fontThirteen poppins-regular input-color">HH:MM XM</span>
          </div>
          <div
            onClick={() => setShowTimeModal(true)}
            onKeyPress={() => setShowTimeModal(true)}
            role="button"
            tabIndex={0}
          >
            <img src={arrowRight} alt="arrow right" className={Styles.iconWidth} />
          </div>
        </div>
      </div>
      <div className={Styles.btnWrapper}>
        <Button label="Cancel" cssClass={Styles.cancelBtn} clickHandler={handleCancel} />
        <Button label="Save Changes" cssClass={`${Styles.saveBtn}`} clickHandler={handleChanges} />
      </div>
      {showDateModal && (
        <Modal closeDialog={closeDialog}>
          <DateFormat handleClose={() => setShowDateModal(false)} />
        </Modal>
      )}
      {showTimeModal && (
        <Modal closeDialog={closeDialog}>
          <TimeFormat handleClose={() => setShowTimeModal(false)} />
        </Modal>
      )}
    </>
  );
};

export default DateAndTime;
