import React, { FunctionComponent, useEffect, useState } from 'react';
import Header from 'components/header/Header';
import Styles from 'views/broadCastDetails/BroadCastDetails.module.scss';
import { Link } from 'react-router-dom';
import BroadCastDetail from 'components/broadCastDetail/BroadCastDetail';
import sendImage from 'assets/svg/send_blue.svg';
import RoundButton from 'components/button/roundButton/RoundButton';
import { User } from 'modules/user/types';
import { useLocation } from 'react-router';

interface BroadCastDetailsProps {
  user: User;
}

const BroadCastDetails: FunctionComponent<BroadCastDetailsProps> = (props: BroadCastDetailsProps) => {
  const { user } = props;
  const location = useLocation();
  const [item, setItem] = useState(location.state);
  useEffect(() => {
    setItem(location.state);
  }, [location.state]);
  return (
    <>
      <Header user={user} />
      <div className={`pt-40 pb-4 ${Styles.DetailsWrapper}`}>
        <div className="custom-container">
          <Link to="/" className="text-uppercase text-decoration-none fontSixteen text-blue poppins-bold mb-40 d-block">
            <i className="fas fa-arrow-left pr-2" />
            <span className="mb-0">BACK TO DASHBOARD</span>
          </Link>
          <div className="mt60">
            <BroadCastDetail item={item} />
          </div>
          <div className={`mt-35 d-flex justify-content-center column-gap-30 ${Styles.actionsWrapper}`}>
            <RoundButton icon={sendImage} />
            <RoundButton icon={sendImage} />
          </div>
        </div>
      </div>
    </>
  );
};
export default BroadCastDetails;
