import { call, put, all, takeEvery } from 'redux-saga/effects';
import { generalActions } from 'store/actions';
import ACTIONS from 'store/constants';
import ApiResponse, { joinStreamResponse } from 'modules/response/response.types';
import { IBroadCastParam, IScheduleBroadCastParam, ICreateChannel, IJoinStream } from 'modules/params/param.type';
import api from 'store/services/general.services';
import { createBroadCast, scheduleBroadCast } from 'store/services/firebase-broadcast';
import { BroadCast } from 'modules/user/types';

function* createChannel(state: { type: string; payload: ICreateChannel; params: IBroadCastParam }) {
  try {
    yield put(generalActions.createChannel.pending);
    const response: ApiResponse = yield call(api.createChannel, state.payload);
    const channel: BroadCast = yield call(
      createBroadCast,
      Object.assign(state.params, { streamId: response.data.streamId }),
    );
    console.log('channel create', channel);
    if (Object.keys(channel).length > 0) {
      yield put(generalActions.createChannel.success(channel));
    }
  } catch (error: any) {
    yield put(generalActions.createChannel.error(error.message));
  }
}

function* createJoinStream(state: { type: string; payload: IJoinStream; params: IBroadCastParam }) {
  try {
    yield put(generalActions.createJoinStream.pending);
    const response: joinStreamResponse = yield call(api.createStream, state.payload);
    if (response.id) {
      const obj: IBroadCastParam = state.params;
      yield (obj.streamId = response.id);
      const responseBroad: ApiResponse = yield call(createBroadCast, obj);
      yield put(generalActions.createJoinStream.success(responseBroad));
    }
  } catch (error: any) {
    yield put(generalActions.createJoinStream.error(error.message));
  }
}

function* createScheduleBroadCast(state: { type: string; params: IScheduleBroadCastParam }) {
  try {
    const obj: IScheduleBroadCastParam = state.params;
    // @ts-ignore
    yield call(scheduleBroadCast, obj);
  } catch (error: any) {
    yield put(generalActions.createJoinStream.error(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.CREATE_BROADCAST_BEGIN, createChannel),
    takeEvery(ACTIONS.JOIN_STREAM_BEGIN, createJoinStream),
    takeEvery(ACTIONS.SCHEDULE_STREAM_BEGIN, createScheduleBroadCast),
  ]);
}
