import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/svg/logo_small.svg';
import profileIcon from 'assets/images/user.png';
import Styles from 'components/header/Header.module.scss';
import { User } from 'modules/user/types';

interface HeaderProps {
  user: User;
}
const Header: FunctionComponent<HeaderProps> = (props: HeaderProps) => {
  const { user } = props;

  return (
    <header>
      <div className="custom-container h-100">
        <div className={Styles.headerWrapper}>
          <Link to="/broadcasting" className="d-inline-block">
            <img src={logo} alt="logo" className={Styles.logo} />
          </Link>
          <div className={Styles.profileName}>
            <p className="mb-0 fontSixteen text-black font-weight-bolder text-capitalize">{user.fullname}</p>
            <Link to="/profile" className={`d-inline-block ${Styles.profileLogoWrapper}`}>
              <img src={profileIcon} alt="profile icon" className={Styles.profileIcon} />
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
