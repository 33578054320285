import { JitsiMeeting } from '@jitsi/web-sdk/lib/components';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { User, BroadCast } from '../../modules/user/types';
import { updateBroadcast } from '../../store/services/firebase-broadcast';

type participant = { id: string; name: string };

interface JitsiProps {
  user: User;
  broadCast: BroadCast;
  handleClose: Function;
}

const Jitsi: FunctionComponent<JitsiProps> = (props: JitsiProps) => {
  const { user, broadCast, handleClose } = props;
  const room = broadCast.room_id;
  const apiRef = useRef<any>();
  const [showChat, setShowChat] = useState<boolean>(true);
  const [logItems, updateLog] = useState<string[]>([]);
  const [knockingParticipants, updateKnockingParticipants] = useState<participant[]>([]);
  console.log('Broadcast Participants : ', knockingParticipants);
  useEffect(
    () => () => {
      localStorage.setItem('apiRef', apiRef.current);
      // if (apiRef && apiRef.current) {
      //   apiRef.current = '';
      // }
    },
    [apiRef],
  );

  const isVideoMuted = () => {
    if (broadCast.streamType === 'Audio') {
      return true;
    }
    return false;
  };
  useEffect(() => {
    const { status } = broadCast;
    if (status === 'ended') {
      handleReadyToClose();
    }
  }, [broadCast]);
  const printEventOutput = (payload: any) => {
    updateLog((items: string[]) => [...items, JSON.stringify(payload)]);
  };

  const handleAudioStatusChange = (payload: any, feature: any) => {
    if (payload.muted) {
      updateLog((items) => [...items, `${feature} off`]);
    } else {
      updateLog((items) => [...items, `${feature} on`]);
    }
  };

  const handleChatUpdates = (payload: any, ref: any) => {
    if (payload.isOpen || !payload.unreadCount) {
      return;
    }
    ref.current.executeCommand('toggleChat');
    updateLog((items: string[]) => [...items, `you have ${payload.unreadCount} unread messages`]);
  };

  const handleKnockingParticipant = (payload: participant) => {
    updateLog((items: any) => [...items, JSON.stringify(payload)]);
    updateKnockingParticipants((participants: participant[]) => [...participants, payload]);
  };

  const resolveKnockingParticipants = async () => {
    const data: any = apiRef.current.getParticipantsInfo();
    console.log('Total Participants :', apiRef, data);
    // console.log('[resolveKnockingParticipants]', ref, condition);
    // knockingParticipants.forEach((p: participant) => {
    //   ref.current.executeCommand('answerKnockingParticipant', p?.id, condition(p));
    //   updateKnockingParticipants((participants) => participants.filter((item) => item.id === p.id));
    // });
  };

  const handleJitsiIFrameRef = (iframeRef: any) => {
    const iFrameReference: any = iframeRef;
    iFrameReference.id = 'jitsiMeeting-0';
    iFrameReference.style.border = '0 !important';
    iFrameReference.style.backgroundImage = 'linear-gradient(to bottom, #00AEEF, #FFFFFF) !important';
    iFrameReference.style.height = '100vh !important';
    iFrameReference.style.width = '100% !important';
    iFrameReference.style.position = 'relative';
  };

  const handleReadyToClose = async () => {
    if (apiRef && apiRef.current) {
      // apiRef.current.dispose();
      // apiRef.current = '';
      await updateBroadcast('ended', broadCast, user).then((r) => console.log('Callback: ', r));
      handleClose();
    }
  };

  const handleVideoConferenceJoined = (e: any) => {
    console.log('Conference Joined ', e);
  };

  const handleVideoConferenceLeft = async (e: any) => {
    setShowChat(false);
    if (e.roomName) {
      if (apiRef && apiRef.current) {
        // apiRef.current = '';
        await updateBroadcast('ended', broadCast, user);
        handleClose();
      }
    }
  };

  const handleParticipantKickedOut = (e: any) => {
    console.log('Participant kicked off ', e);
    setShowChat(false);
  };

  const handleApiReady = (apiObj: any, ref: any) => {
    const reff: any = ref;
    reff.current = apiObj;
    reff.current.addEventListeners({
      // Listening to events from the external API
      audioMuteStatusChanged: (payload: any) => handleAudioStatusChange(payload, 'audio'),
      videoMuteStatusChanged: (payload: any) => handleAudioStatusChange(payload, 'video'),
      raiseHandUpdated: printEventOutput,
      tileViewChanged: printEventOutput,
      chatUpdated: (payload: any) => handleChatUpdates(payload, ref),
      knockingParticipant: handleKnockingParticipant,
      readyToClose: handleReadyToClose,
      participantKickedOut: handleParticipantKickedOut,
      videoConferenceJoined: handleVideoConferenceJoined,
      videoConferenceLeft: handleVideoConferenceLeft,
    });
  };

  const renderButtons = () => (
    <div style={{ margin: '15px 0' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <button
          type="button"
          title="Click to execute toggle Mute hand command"
          style={{
            border: 0,
            borderRadius: '6px',
            fontSize: '14px',
            background: '#1bff1f',
            color: 'white',
            padding: '12px 46px',
            margin: '2px 2px',
          }}
          onClick={() => {
            if (apiRef && apiRef.current) {
              apiRef.current.executeCommand('toggleAudio');
            }
          }}
        >
          Mute
        </button>
        <button
          type="button"
          title="Click to execute toggle Video command"
          style={{
            border: 0,
            borderRadius: '6px',
            fontSize: '14px',
            background: '#3221ff',
            color: 'white',
            padding: '12px 46px',
            margin: '2px 2px',
          }}
          onClick={() => {
            if (apiRef && apiRef.current) {
              apiRef.current.executeCommand('toggleVideo');
            }
          }}
        >
          Video
        </button>
        <button
          type="button"
          title="Click to execute toggle raise hand command"
          style={{
            border: 0,
            borderRadius: '6px',
            fontSize: '14px',
            background: '#ff9b42',
            color: 'white',
            padding: '12px 46px',
            margin: '2px 2px',
          }}
          onClick={() => {
            if (apiRef && apiRef.current) {
              apiRef.current.executeCommand('toggleRaiseHand');
            }
          }}
        >
          Raise hand
        </button>
        <button
          type="button"
          title="Click to approve/reject knocking participant"
          style={{
            border: 0,
            borderRadius: '6px',
            fontSize: '14px',
            background: '#0376da',
            color: 'white',
            padding: '12px 46px',
            margin: '2px 2px',
          }}
          onClick={resolveKnockingParticipants}
        >
          Resolve lobby
        </button>
        {/* <button */}
        {/*  type="button" */}
        {/*  style={{ */}
        {/*    border: 0, */}
        {/*    borderRadius: '6px', */}
        {/*    fontSize: '14px', */}
        {/*    background: '#a7a7a7', */}
        {/*    color: 'white', */}
        {/*    padding: '12px 46px', */}
        {/*    margin: '2px 2px', */}
        {/*  }} */}
        {/*  onClick={() => { */}
        {/*    if (room.length === 0) return; */}
        {/*    setShowChat(true); */}
        {/*  }} */}
        {/* > */}
        {/*  Join Room */}
        {/* </button> */}
      </div>
    </div>
  );
  console.log(renderButtons);
  const renderLog = () =>
    logItems.map((item, index) => (
      <div
        style={{
          fontFamily: 'monospace',
          padding: '5px',
        }}
        key={index}
      >
        {item}
      </div>
    ));

  const renderSpinner = () => (
    <div
      style={{
        fontFamily: 'sans-serif',
        textAlign: 'center',
      }}
    >
      Loading..
    </div>
  );
  console.log(renderLog());
  return (
    <>
      {/* {renderButtons()} */}
      {showChat && (
        <div className="d-flex">
          <JitsiMeeting
            // domain="https://11ec-2400-adc5-1a5-2300-7149-6424-604d-61c8.ngrok.io"
            domain="broadcast.soundbirdapp.com"
            // domain="meet.jit.si"
            roomName={room}
            userInfo={{
              displayName: user.fullname || '',
              email: user.email || '',
              // avatar: user.avatar,
            }}
            spinner={renderSpinner}
            configOverwrite={{
              prejoinPageEnabled: false,
              disableDeepLinking: true,
              disableKick: true,
              startWithVideoMuted: isVideoMuted(),
            }}
            interfaceConfigOverwrite={{
              SHOW_JITSI_WATERMARK: true,
              DEFAULT_LOGO_URL: user.avatar,
              JITSI_WATERMARK_LINK: user.avatar,
            }}
            onApiReady={(externalApi) => handleApiReady(externalApi, apiRef)}
            getIFrameRef={handleJitsiIFrameRef}
          />
        </div>
      )}
      {/* {renderLog()} */}
    </>
  );
};

export default Jitsi;
