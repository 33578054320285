import React, { FunctionComponent, useEffect, useState } from 'react';
import Button from 'components/button/Button';
import InputField from 'components/textField/InputField';
import { Link, useHistory } from 'react-router-dom';
import Styles from 'views/register//Register.module.scss';
import api from 'store/services/auth.services';
import { checkEmail } from 'utils/FormValidation';
import LoginLeftSection from 'components/loginLeftSection/LoginLeftSection';
import { LOADER_STATUS } from '../../store/constants';

interface ForgetParams {}
const ForgetPassword: FunctionComponent<ForgetParams> = () => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<number>(LOADER_STATUS.NA);
  const [emailExist, setEmailExist] = useState<number>(LOADER_STATUS.NA);
  const [isCheckLoading, setIsCheckLoading] = useState<number>(-1);

  const history = useHistory();

  useEffect(() => {
    validateEmail();
  });

  const handleRequest = async () => {
    if (emailError.length > 0 || email.length === 0) {
      return;
    }
    if (isCheckLoading === LOADER_STATUS.NA) {
      const check = await checkEmailExist(email);
      if (check) return;
    }
    if (isCheckLoading === LOADER_STATUS.COMPLETE && emailExist === 1) {
      try {
        setIsLoading(LOADER_STATUS.PENDING);
        await api.forgetPassword({ email }).then((res) => {
          if (res) {
            setIsLoading(LOADER_STATUS.COMPLETE);
            history.push({
              pathname: '/restorePassword',
              state: { email },
            });
          }
        });
      } catch (err: any) {
        setIsLoading(LOADER_STATUS.COMPLETE);
      }
    }
  };

  const checkEmailExist = async (val: string) => {
    if (emailError.length === 0 && val.length > 0) {
      try {
        setIsCheckLoading(LOADER_STATUS.PENDING);
        await api.checkEmailExist(email).then((res: any) => {
          setIsCheckLoading(LOADER_STATUS.COMPLETE);
          if (res.status === 'registered') {
            setEmailExist(1);
            return false;
          }
          if (res.status === 'available') {
            setEmailExist(0);
            return true;
          }
          return false;
        });
      } catch (err) {
        setIsCheckLoading(LOADER_STATUS.COMPLETE);
        return true;
      }
    }
    return false;
  };
  const handleBlur = (event: React.FormEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    setEmailError(checkEmail(val));
    checkEmailExist(val);
  };
  const handleKeyDownEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleRequest();
    }
  };

  const validateEmail = () => setEmailError(checkEmail(email));

  return (
    <div className={Styles.customContainer}>
      <div className={Styles.leftSectionWrapper}>
        <LoginLeftSection />
      </div>
      <div className={Styles.rightSectionWrapper}>
        <div className={Styles.loginArea}>
          <h4 className={Styles.accountHeading}>Forget your account password</h4>
          <div className={`mb-0 ${Styles.formWrapper}`}>
            <div className="form-group">
              <InputField
                errorMessage={emailExist === 0 ? 'Email Not Exists' : emailError}
                handleKeyDown={handleKeyDownEvent}
                blurHandler={handleBlur}
                handleChange={setEmail}
                type="email"
                placeholder="Email Address"
                showLoading={isCheckLoading === LOADER_STATUS.PENDING}
              />
            </div>
          </div>
          <div className={`text-center ${Styles.signUpMarginBLock}`}>
            <Button showLoading={isLoading === LOADER_STATUS.PENDING} clickHandler={handleRequest} label="Send Code" />
          </div>
          <p className="fontFifteen font-weight-normal text-black">
            Remember your password? &nbsp;
            <Link
              to="/login"
              className="text-blue text-decoration-none display-inline-block fontFifteen font-weight-bolder"
            >
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
