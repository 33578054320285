// export const BASE_URL = 'https://mashghol.com/';
// export const BASE_URL = 'http://localhost:5000/';

export const BASE_URL = 'https://api.soundbirdapp.com/api/v1/';
export const URL_LOGIN = `${BASE_URL}jwt-login/`;
export const URL_SIGNUP = `${BASE_URL}registration/`;
export const URL_EMAIL_EXISTS = `${BASE_URL}registration/check_registered/`;
export const URL_RESEND_REGISTRATION_CODE = `${BASE_URL}registration/resend/`;
export const URL_CONFIRM_REGISTRATION = `${BASE_URL}registration/confirm/`;
export const URL_FORGOT_PASSWORD = `${BASE_URL}restore_password/`;
export const URL_RESTORE_PASSWORD = `${BASE_URL}restore_password/restore/`;
export const URL_GET_USER = `${BASE_URL}accounts/`;

export const URL_JOIN_STREAM = `${BASE_URL}streams/`;
