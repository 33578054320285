import { db, storage, reference, getDownloadURL, uploadBytesResumable } from 'utils/firebase-config';
import { ref, onValue, set } from 'firebase/database';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { STREAM_TYPE } from 'store/constants';
import { IBroadCastParam, IScheduleBroadCastParam } from 'modules/params/param.type';
import { BroadCast } from 'modules/user/types';

export const useBroadcastList = () => {
  const [broadCasts, setBroadCasts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onValue(ref(db, 'broadcasts'), (querySnapshot: any) => {
      const channel: any = [];
      querySnapshot.forEach((doc: any) => {
        channel.push(doc.val());
      });
      setLoading(false);
      setBroadCasts(channel);
    });
    return () => unsubscribe();
  }, []);
  return { broadCasts, loading };
};

export const createBroadCast = async (params: IBroadCastParam) => {
  try {
    const creationTime: any = moment().toDate().getTime();
    // console.log('[firebase-broadcast]createBroadCast', moment().toDate().getTime());
    // const creationTime = moment().unix();
    // const creationTime = moment().milliseconds();
    const refBroadcasts = ref(db, `broadcasts/${params.channelName}`);
    const obj: BroadCast = {
      admin: params.admin,
      dateCreated: creationTime,
      dateUpdated: creationTime,
      location: params.location,
      room_id: params.channelName,
      stream_id: params.streamId || '',
      subscription_type: 'limited',
      status: 'open',
      areaLimit: params.areaLimit,
      streamType: params.streamType,
      ui_mode: params.streamType === 'Video' ? STREAM_TYPE.VIDEO : STREAM_TYPE.AUDIO,
    };
    await set(refBroadcasts, obj);
    return obj;
  } catch {
    return {};
  }
};

export const scheduleBroadCast = async (params: IScheduleBroadCastParam) => {
  try {
    const creationTime: any = moment().toDate().getTime();
    const refBroadcasts = ref(db, `broadcasts/${params.channelName}`);
    const obj: BroadCast = {
      admin: params.admin,
      areaLimit: params.areaLimit,
      dateCreated: creationTime,
      dateUpdated: creationTime,
      details: params.details,
      location: params.location,
      place: params.place,
      room_id: params.channelName,
      startTime: moment(params.startTime).toDate().getTime(),
      status: 'scheduled',
      subscribers: {},
    };
    await set(refBroadcasts, obj);
    return obj;
  } catch {
    return {};
  }
};

export const updateBroadcast = async (conferenceStatus: string, broadCast: any, user: any) => {
  const creationTime: any = moment().format('YYYY-MM-DD HH:mm:ss');
  // const creationTime = moment().getTime(); //for milliseconds
  let isListener = false;
  if (user.uid !== broadCast.admin.uid) {
    isListener = true;
  }
  const broadcastForListener: any = ref(db, `broadcasts/${broadCast.room_id}/users/${user.id}`);
  const broadcastForBroadcaster: any = ref(db, `broadcasts/${broadCast.room_id}`);
  if (isListener) {
    switch (conferenceStatus) {
      case 'offline':
        broadcastForListener.remove();
        break;
      case 'open':
        broadcastForListener.set(user);
        break;
      default:
        break;
    }
    return;
  }

  // For Broadcaster
  // if (conferenceStatus === 'open') {
  // const context = this;
  try {
    const obj: BroadCast = {
      admin: broadCast.admin,
      dateCreated: broadCast.dateCreated,
      dateUpdated: creationTime,
      location: broadCast.location,
      room_id: broadCast.room_id,
      stream_id: broadCast.stream_id,
      subscription_type: 'limited',
      status: conferenceStatus,
      areaLimit: broadCast.areaLimit,
      streamType: broadCast.streamType,
      ui_mode: broadCast.streamType,
    };
    await set(broadcastForBroadcaster, obj);
  } catch (err) {
    console.log(err);
  }

  //   this.lastLiveInterval = setInterval(() => {
  //     context.updatedLastLiveTime();
  //   }, 180 * 1000);
  // } else {
  //   broadcastForBroadcaster.update({
  //     status: conferenceStatus,
  //   });
  // }
};

export const broadcastImages = async (broadcastId: any) => {
  const images: any = [
    {
      image: '',
    },
    {
      image: '',
    },
    {
      image: '',
    },
  ];
  try {
    const url1 = await getDownloadURL(reference(storage, `/broadcasts/${broadcastId.room_id}/${0}`));
    const url2 = await getDownloadURL(reference(storage, `/broadcasts/${broadcastId.room_id}/${1}`));
    const url3 = await getDownloadURL(reference(storage, `/broadcasts/${broadcastId.room_id}/${2}`));
    if (url1) images[0].image = url1;
    if (url2) images[1].image = url2;
    if (url3) images[2].image = url3;
    return images;
  } catch (err) {
    console.log(err);
  }
  return images;
};

export const uploadBroadcastImage = async (broadcast: any, index: number, file: any) => {
  try {
    console.log('Payload : ', broadcast.channelName, index, file);
    const res = await uploadBytesResumable(reference(storage, `/broadcasts/${broadcast.channelName}/${index}`), file);
    console.log('Res :', res);
    return res;
  } catch (err) {
    console.log(err);
  }
  return '';
};
