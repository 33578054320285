import React, { FunctionComponent } from 'react';
import Styles from 'views/notifications/Notifications.module.scss';
import Button from 'components/button/Button';
import SwitchButton from 'components/button/switchButton/SwitchButton';

const Notifications: FunctionComponent = () => {
  const handleCancel = () => {
    console.log('handle Cancel');
  };
  const handleChanges = () => {
    console.log('handle Changes');
  };
  return (
    <>
      <div className="borderStyle d-flex column-gap-15 justify-content-between align-items-center">
        <div className="text-black fontSixteen poppins-regular">Turn on all notifications?</div>
        <SwitchButton />
      </div>
      <div className={Styles.btnWrapper}>
        <Button label="Cancel" cssClass={Styles.cancelBtn} clickHandler={handleCancel} />
        <Button label="Save Changes" cssClass={`${Styles.saveBtn}`} clickHandler={handleChanges} />
      </div>
    </>
  );
};

export default Notifications;
