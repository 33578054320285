import { fromJS } from 'immutable';
import ACTIONS, { FETCH_STATUS } from '../constants';

const initialState: any = fromJS({
  broadcasts: [],
  selectedBroadCast: null,
  error: '',
  loading: false,
  streamId: null,
});

export default function broadCastReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.SET_SELECTED_BROADCAST: {
      return state.set('selectedBroadCast', action.payload);
    }
    case ACTIONS.CREATE_BROADCAST.SUCCESS: {
      console.log('reucer', action.data);
      return state.set('loading', false).set('error', '').set('selectedBroadCast', action.data);
    }
    case ACTIONS.BROADCAST_CREATED_ON_FIREBASE: {
      return state.set('loading', false).set('selectedBroadCast', action.data);
    }
    case ACTIONS.JOIN_STREAM.PENDING: {
      return state.set('loading', action.status === FETCH_STATUS.LOADING);
    }
    case ACTIONS.JOIN_STREAM.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.JOIN_STREAM.SUCCESS: {
      console.log('reducer streams', action.data);
      return state
        .set('loading', false)
        .set('error', '')
        .set('streamId', action.data.stream_id)
        .set('selectedBroadCast', action.data);
    }
    case ACTIONS.CREATE_BROADCAST.PENDING: {
      return state.set('loading', action.status === FETCH_STATUS.LOADING).set('selectedBroadCast', null);
    }
    case ACTIONS.CREATE_BROADCAST.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    default: {
      return state;
    }
  }
}
