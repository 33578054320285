export const broadCastNearby = [
  {
    breakpoint: 1366,
    settings: {
      // centerMode: true,
      slidesToShow: 6,
      slidesToScroll: 6,
    },
  },
  {
    breakpoint: 1200,
    settings: {
      // centerMode: true,
      slidesToShow: 5,
      slidesToScroll: 5,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      // centerMode: true,
      slidesToShow: 5,
      slidesToScroll: 5,
    },
  },
  {
    breakpoint: 992,
    settings: {
      // centerMode: true,
      slidesToShow: 5,
      slidesToScroll: 5,
    },
  },
  {
    breakpoint: 768,
    settings: {
      // centerMode: true,
      slidesToShow: 4,
      slidesToScroll: 4,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: true,
    },
  },
  {
    breakpoint: 425,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      vertical: false,
      arrows: true,
    },
  },
];

export const scheduleBroadCast = [
  {
    breakpoint: 1366,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 6,
    },
  },
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
    },
  },
  // {
  //   breakpoint: 1124,
  //   settings: {
  //     slidesToShow: 5,
  //     slidesToScroll: 5,
  //   },
  // },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
    },
  },
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
    },
  },
  // {
  //   breakpoint: 600,
  //   settings: {
  //     slidesToShow: 3,
  //     slidesToScroll: 3,
  //     vertical: true,
  //     arrows: true,
  //   },
  // },
];
