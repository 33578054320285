import React, { FunctionComponent, useEffect, useState } from 'react';
import BroadCastType from 'components/newBroadCast/BroadCastType/ChooseBroadCastType';
import SubscriptionPlan from 'components/newBroadCast/SubscriptionPlan/SubscriptionPlan';
import SubscriptionCharges from 'components/newBroadCast/subscriptionCharges/SubscriptionCharges';
import Styles from 'components/newBroadCast/NewBroadCast.module.scss';
import EntryFee from 'components/newBroadCast/entryFee/EntryFee';
import BroadCastDetails from 'components/newBroadCast/broadCastDetails/BroadCastDetails';
import { BROADCAST_STATUS } from 'store/constants';
import { ILocation, BroadCastChannel } from 'modules/types/types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getLoading, getStreamId } from '../../store/selector/broadCast.selector';
import { getUser } from '../../store/selector/auth.selector';
import { BroadCast, User } from '../../modules/user/types';
import { broadCastStorage } from '../../helpers/utils';

interface NewBroadCastProps {
  modal: string;
  createJoinStream: Function;
  modalType: Function;
  modalClass: Function;
  location: ILocation;
  handleClose: Function;
  selectedBroadCast: Function;
  broadCastSelector: BroadCast;
}

const NewBroadCast: FunctionComponent<NewBroadCastProps> = (props: NewBroadCastProps) => {
  const {
    modalType,
    modal,
    modalClass,
    location,
    createJoinStream,
    handleClose,
    selectedBroadCast,
    broadCastSelector,
  } = props;
  const history = useHistory();
  const [bodyType, setBodyType] = useState(modal || BROADCAST_STATUS.TYPE);
  const streamId = useSelector(getStreamId);
  const isLoading: boolean = useSelector(getLoading);
  const user: User = useSelector(getUser);

  const [broadCast, setBroadCast] = useState<BroadCastChannel>({
    ui_mode: '',
    streamType: '',
    plan: '',
    charges: '',
    channelName: '',
    areaLimit: '',
    location,
    admin: {
      uid: user.uid || '',
      name: user.fullname || '',
      avatar: user.avatar || '',
    },
  });
  modalType(bodyType);
  useEffect(() => {
    if (!isLoading && streamId && broadCastSelector) {
      handleClose();
      broadCastStorage.save(broadCastSelector);
      selectedBroadCast(broadCastSelector);
      history.push('/audiobroadcast');
    }
  }, [isLoading]);

  useEffect(() => {
    if (broadCast.channelName && broadCast.areaLimit) createJoinStream({ name: broadCast.channelName }, broadCast);
  }, [broadCast.areaLimit, broadCast.channelName]);

  const handleStartBroadCast = (obj: any) => updateObject({ channelName: obj.title, areaLimit: obj.range.value });

  const updateObject = (obj: object) => {
    setBroadCast({
      ...broadCast,
      ...obj,
    });
  };
  const getContent = (value: string) => {
    switch (value) {
      case BROADCAST_STATUS.TYPE:
        return (
          <BroadCastType
            cssClass={Styles.broadCastWidth}
            broadCast={updateObject}
            navigate={setBodyType}
            selectedType={broadCast.streamType}
          />
        );
      case BROADCAST_STATUS.SUBSCRIPTION:
        return (
          <SubscriptionPlan
            cssClass={Styles.chooseBroadCastWidth}
            broadCast={updateObject}
            navigate={setBodyType}
            selectedPlan={broadCast.plan}
            modalClass={modalClass}
          />
        );
      case BROADCAST_STATUS.SUBSCRIPTION_CHARGES:
        return <SubscriptionCharges broadCast={updateObject} navigate={setBodyType} selectedBroadcast={broadCast} />;
      case BROADCAST_STATUS.ENTRY_FEE:
        return <EntryFee navigate={setBodyType} />;
      case BROADCAST_STATUS.BROADCAST_DETAILS:
        return (
          <BroadCastDetails
            loading={isLoading}
            handleStartBroadCast={handleStartBroadCast}
            broadcast={broadCast}
            navigate={setBodyType}
          />
        );
      default:
        return (
          <BroadCastType
            cssClass={Styles.broadCastWidth}
            broadCast={updateObject}
            navigate={setBodyType}
            selectedType={broadCast.streamType}
          />
        );
    }
  };
  return getContent(bodyType);
};
export default NewBroadCast;
