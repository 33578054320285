import React, { FunctionComponent } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Styles from 'components/leftNavBar/LeftNavBar.module.scss';
import Button from 'components/button/Button';
import { useDispatch } from 'react-redux';
import { callLogout } from '../../store/actions/auth.actions';

const LeftNavBar: FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const path = location.pathname;
  const dispatch = useDispatch();
  const handleLogOut = () => {
    dispatch(callLogout());
    history.push('/login');
  };
  return (
    <>
      <ul className={`list-unstyled ${Styles.listingWrapper}`}>
        <li className={`fontSixteen text-black poppins-bold ${Styles.listingTitle}`}>GENERAL</li>
        <li className={`${path === '/explainervideo' && Styles.active}`}>
          <a
            className="fontFifteen poppins-regular text-decoration-none"
            href="https://www.youtube.com/watch?v=JQrPpj7b0_g"
            target="_blank"
            rel="noreferrer"
          >
            Explainer Video
          </a>
        </li>
        <li className={`${path === '/share' && Styles.active}`}>
          <Link
            className="fontFifteen poppins-regular text-decoration-none"
            to="/"
            onClick={(e: any) => e.preventDefault()}
          >
            Share
          </Link>
        </li>
        <li className={`fontSixteen text-black poppins-bold ${Styles.listingTitle}`}>ACCOUNT</li>
        <li className={`${path === '/subscription' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/subscription">
            Subscription
          </Link>
        </li>
        <li className={`${path === '/balance' && Styles.active}`}>
          <Link
            className="fontFifteen poppins-regular text-decoration-none"
            to="/"
            onClick={(e: any) => e.preventDefault()}
          >
            Balance $
          </Link>
        </li>
        <li className={`${path === '/profile' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/profile">
            Edit Profile
          </Link>
        </li>
        <li className={`${path === '/dateandtime' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/dateandtime">
            Date and Time
          </Link>
        </li>
        <li className={`${path === '/notifications' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/notifications">
            Notifications
          </Link>
        </li>
        <li className={`fontSixteen text-black poppins-bold ${Styles.listingTitle}`}>OTHER</li>
        <li className={`${path === '/feedback' && Styles.active}`}>
          <Link
            className="fontFifteen poppins-regular text-decoration-none"
            to="/"
            onClick={(e: any) => e.preventDefault()}
          >
            Send Us Feedback
          </Link>
        </li>
        <li className={`${path === '/termsandconditions' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/termsandconditions">
            Terms & Conditions
          </Link>
        </li>
        <li className={`${path === '/privacypolicy' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/privacypolicy">
            Privacy Policy
          </Link>
        </li>
        <li className={Styles.logOut}>
          <Button label="LogOut" cssClass={Styles.btnStyle} clickHandler={handleLogOut} />
        </li>
      </ul>
    </>
  );
};

export default LeftNavBar;
