import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import Styles from 'components/settings/Subscriptions/SubscriptionItem.module.scss';
import freePlanImage from 'assets/svg/phone_white.svg';
import freePlanSelected from 'assets/svg/phone_orange.svg';
import monthlyPlanImage from 'assets/svg/devices_white.svg';
import monthlyPlanSelected from 'assets/svg/devices_orange.svg';
import entryFeeImage from 'assets/svg/ticket_white.svg';
import entryFeeSelected from 'assets/svg/ticket_orange.svg';

const SubscriptionItem: FunctionComponent = () => {
  const [active, setActive] = useState('');
  const handleClick = (title: string) => {
    setActive(title);
  };
  return (
    <>
      <div
        className={`${Styles.subscriptionItemWrapper} ${active === 'Free' && Styles.active}`}
        onClick={() => handleClick('Free')}
        onKeyPress={() => handleClick('Free')}
        role="button"
        tabIndex={0}
      >
        <div className={Styles.planName}>
          <img src={active === 'Free' ? freePlanImage : freePlanSelected} alt="" />
          <h4 className="mb-0 poppins-bold">Free</h4>
        </div>
        <div className={Styles.detailsWrapper}>
          <p className="mb-0 fontSixteen poppins-regular">Broadcast to a maximum of 5 devices. No time limit.</p>
        </div>
      </div>
      <div
        className={`${Styles.subscriptionItemWrapper} ${active === 'Monthly' && Styles.active}`}
        onClick={() => handleClick('Monthly')}
        onKeyPress={() => handleClick('Monthly')}
        role="button"
        tabIndex={0}
      >
        <div className={Styles.planName}>
          <img src={active === 'Monthly' ? monthlyPlanImage : monthlyPlanSelected} alt="" />
          <h4 className="mb-0 poppins-bold">Monthly</h4>
        </div>
        <div className={Styles.detailsWrapper}>
          <p className="mb-0 fontSixteen poppins-regular">
            Broadcast to a maximum of 3000 devices. If more is needed contact us &nbsp;
            <Link to="/">here.</Link>
          </p>
        </div>
      </div>
      <div
        className={`${Styles.subscriptionItemWrapper} ${active === 'Entry Fee' && Styles.active}`}
        onClick={() => handleClick('Entry Fee')}
        onKeyPress={() => handleClick('Entry Fee')}
        role="button"
        tabIndex={0}
      >
        <div className={Styles.planName}>
          <img src={active === 'Entry Fee' ? entryFeeImage : entryFeeSelected} alt="" />
          <h4 className="mb-0 poppins-bold">Entry Fee</h4>
        </div>
        <div className={Styles.detailsWrapper}>
          <p className="mb-0 fontSixteen poppins-regular">
            Also known as pay per View, Charge listeners an entry fee to join your broadcast. A 20% commission will be
            charged on the transaction and the remaining balance will be deposited in your account.
          </p>
        </div>
      </div>
    </>
  );
};

export default SubscriptionItem;
