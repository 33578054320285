import React, { FunctionComponent, useEffect, useState } from 'react';
import Header from 'components/header/Header';
import Styles from 'views/broadCasting/broadCasting.module.scss';
import Button from 'components/button/Button';
import BroadCastCards from 'components/broadCastCards/BroadCastCards';
import BroadCastScheduleCards from 'components/broadCastCards/BroadCastScheduleCards';
import Modal from 'components/modal/Modal';
import { useBroadcastList } from 'store/services/firebase-broadcast';
import { BroadCast, BroadCastList, User } from 'modules/user/types';
import { calculateDistance } from 'helpers/utils';
import { ILocation } from 'modules/types/types';
import NewBroadCast from 'components/newBroadCast/NewBroadCast';
import ScheduleBroadCast from 'components/newBroadCast/scheduleBroadCast/ScheduleBroadCast';
import SliderWrapper from 'components/controls/Slider/SliderWrapper';
import { broadCastNearby } from 'components/controls/Slider/SliderResponsiveness';
import moment from 'moment';

interface BroadCastingProps {
  createChannelBegin: Function;
  createJoinStream: Function;
  createSchedule: Function;
  setSelectedBroadCast: Function;
  user: User;
  selectedBroadCast: BroadCast;
}
const BroadCasting: FunctionComponent<BroadCastingProps> = (props: BroadCastingProps) => {
  const { createChannelBegin, setSelectedBroadCast, user, createJoinStream, createSchedule, selectedBroadCast } = props;
  const time = new Date().getTime();
  const [userLocation, setUserLocation] = useState<ILocation>({ latitude: 0, longitude: 0 });
  const [status, setStatus] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [scheduleBroadCastModal, setScheduleBroadCastModal] = useState(false);
  const [currentDate] = useState(new Date(time));
  const { broadCasts } = useBroadcastList();
  let activeBroadCasts: BroadCastList = broadCasts.filter((e: any) => {
    if (e.status === 'open' && !(moment().diff(moment(e.dateUpdated), 'milliseconds') > 6 * 60 * 1000)) {
      return e;
    }
    return '';
  });
  useEffect(() => {
    activeBroadCasts = broadCasts.filter((e: any) => {
      if (e.status === 'open' && !(moment().diff(moment(e.dateUpdated), 'milliseconds') > 6 * 60 * 1000)) {
        return e;
      }
      return '';
    });
  }, [broadCasts]);
  const scheduleBroadCastList = broadCasts.filter((e: any) => {
    // const date = new Date(e.startTime);
    if (e.status === 'scheduled' && currentDate < new Date(e.startTime)) {
      // console.log('scheduleBroadCastList', date.toString());
      return e;
    }
    return '';
  });
  const [modalType, setModalType] = useState('type');
  const [modalClass, setModalClass] = useState('');
  useEffect(() => {
    getLocation();
  }, []);
  const startBroadCast = () => {
    console.log('startBroadCast', status);
    document.body.classList.add('modal-open');
    setModalType('type');
    setShowModal(true);
    if (userLocation) {
      console.log(
        calculateDistance(userLocation.latitude, userLocation.longitude, userLocation.latitude, userLocation.longitude),
      );
    }
  };

  const closeDialog = () => {
    document.body.classList.remove('modal-open');
    setShowModal(false);
    setScheduleBroadCastModal(false);
    handleCreateBroadCast();
  };

  const handleCreateBroadCast = () => console.log(createChannelBegin);

  const scheduleBroadCast = () => {
    document.body.classList.add('modal-open');
    setModalType('scheduleBroadCast');
    setScheduleBroadCastModal(true);
  };
  const handleCloseSchedule = () => {
    document.body.classList.remove('modal-open');
    setScheduleBroadCastModal(false);
  };
  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus('Geolocation is not supported by your browser');
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      () => {
        setStatus('Unable to retrieve your location');
      },
    );
  };
  return (
    <>
      <Header user={user} />
      <section className="container">
        <div className="pt-40 text-center w-100">
          <h4 className={`mb-3 text-black font-weight-bold ${Styles.broadCastHeading}`}>Broadcasts Nearby</h4>
          {activeBroadCasts && activeBroadCasts.length > 0 ? (
            <div className="mb-4">
              <SliderWrapper sliderSetting={broadCastNearby} slideToShow={6} goto={activeBroadCasts.length}>
                {activeBroadCasts.map(
                  (i: BroadCast, index: number) =>
                    i.admin.uid && (
                      <BroadCastCards
                        selectedBroadCast={setSelectedBroadCast}
                        item={i}
                        key={index}
                        location={userLocation}
                      />
                    ),
                )}
              </SliderWrapper>
            </div>
          ) : (
            <div className={Styles.noBroadCastBox}>
              <p className="fontThirteen text-black mb-0 font-weight-500">No Active Broadcasts</p>
            </div>
          )}
          <div className="w-100 text-center">
            <Button cssClass={Styles.startBroadCast} label="Start BroadCast" clickHandler={startBroadCast} />
          </div>
        </div>
        <hr className="horizontal-line" />
        <div className="pb-4 text-center w-100">
          <h4 className={`mb-3 text-black font-weight-bold ${Styles.broadCastHeading}`}>Broadcasts Schedule</h4>
          {scheduleBroadCastList && scheduleBroadCastList.length > 0 ? (
            <div>
              <SliderWrapper sliderSetting={broadCastNearby} slideToShow={6} goto={scheduleBroadCastList.length}>
                {scheduleBroadCastList.map((i: BroadCast, index: number) => (
                  <BroadCastScheduleCards item={i} key={index} location={userLocation} user={user} />
                ))}
              </SliderWrapper>
            </div>
          ) : (
            <div className={Styles.noBroadCastBox}>
              <p className="fontThirteen text-black mb-0 font-weight-500">No Schedule Broadcasts</p>
            </div>
          )}
          <Button
            label="SCHEDULE BROADCAST"
            cssClass={`mt-4 ${Styles.startBroadCast}`}
            clickHandler={scheduleBroadCast}
          />
        </div>
        {showModal && (
          <Modal closeDialog={closeDialog} type={modalType} cssClass={modalClass}>
            <NewBroadCast
              broadCastSelector={selectedBroadCast}
              handleClose={closeDialog}
              createJoinStream={createJoinStream}
              location={userLocation}
              modalType={(e: string) => setModalType(e)}
              modal={modalType}
              modalClass={setModalClass}
              selectedBroadCast={setSelectedBroadCast}
            />
          </Modal>
        )}
        {scheduleBroadCastModal && (
          <Modal closeDialog={closeDialog} type={modalType}>
            <ScheduleBroadCast
              location={userLocation}
              createSchedule={createSchedule}
              closeDialog={handleCloseSchedule}
            />
          </Modal>
        )}
      </section>
    </>
  );
};
export default BroadCasting;
