import React, { FunctionComponent } from 'react';
import DateTimePicker from 'react-datetime-picker';

interface DateTimeFieldProps {
  value: any;
  onChange: any;
}
const DateTimeField: FunctionComponent<DateTimeFieldProps> = (props: DateTimeFieldProps) => {
  const { value, onChange } = props;

  return (
    <div>
      <DateTimePicker format="dd/MM/yyyy h:mm a" onChange={onChange} value={value} />
    </div>
  );
};
export default DateTimeField;
