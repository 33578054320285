import React, { FunctionComponent, useEffect, useState } from 'react';
import timeImage from 'assets/svg/time_orange.svg';
import dateImage from 'assets/svg/date.svg';
import locationImage from 'assets/svg/location.svg';
import distanceImage from 'assets/svg/distance.svg';
import Styles from 'components/broadCastDetail/BroadCastDetail.module.scss';
import BroadCastDetailImages from 'components/broadCastDetailImages/BroadCastDetailImages';
import userIcon from 'assets/images/user.png';
import { setTime, getDateTime } from 'helpers/utils';
import { broadcastImages } from 'store/services/firebase-broadcast';

interface BroadCastDetailsProps {
  item: any;
}

const BroadCastDetail: FunctionComponent<BroadCastDetailsProps> = (props: BroadCastDetailsProps) => {
  const { item } = props;
  const [images, setImages] = useState<any>([]);
  const [countdown, setCountdown] = useState('00 M 00 S');
  const createdTime = (time: any) => {
    const startTime = new Date(time);
    return getDateTime(startTime);
  };
  useEffect(() => {
    let dateDiff = (item.item.startTime - new Date().getTime()) / 1000;
    let countdownTimerText = '';
    if (Math.abs(dateDiff) <= 60 * 60) {
      const timer = setInterval(() => {
        dateDiff = (item.item.startTime - new Date().getTime()) / 1000;
        if (dateDiff <= 0) {
          clearInterval(timer);
          return;
        }
        if (dateDiff < 0) {
          countdownTimerText = '-';
          dateDiff *= -1;
        }

        const ss = Math.floor(dateDiff % 60);
        dateDiff /= 60;
        const mm = Math.floor(dateDiff % 60);
        countdownTimerText = `${mm} M ${ss} S`;
        setCountdown(countdownTimerText);
      }, 1000);
    } else {
      const time = setTime(item.item.startTime);
      setCountdown(time);
    }
  }, []);
  useEffect(() => {
    broadcastImages(item.item).then((e) => {
      setImages(e);
    });
  }, []);
  return (
    <div className={Styles.festivalWrapper}>
      <div className="d-flex align-items-center column-gap-15">
        <img src={(item && item.item.admin?.avatar) || userIcon} alt="user icon" className={Styles.userIcon} />
        <h4 className="fontTwenty poppins-bold mb-0">{item.item.admin.name}</h4>
      </div>
      <div className={Styles.cardWrapper}>
        <div className={Styles.cardInnerLeft}>
          <h3 className={`poppins-bold ${Styles.cardTitle}`}>{item.item.room_id}</h3>
          <div className={Styles.cardInfoWrapper}>
            <div className="d-inline-flex align-items-center column-gap-10">
              <img src={timeImage} alt="time" />
              <span className={Styles.countdown}>{countdown}</span>
            </div>
            <div className="d-inline-flex align-items-center column-gap-10">
              <img src={dateImage} alt="date" />
              <span className="fontFifteen poppins-regular text-black">{createdTime(item.item.startTime)}</span>
            </div>
            <div className="d-inline-flex align-items-center column-gap-10">
              <img src={locationImage} alt="time" />
              <span className="fontFifteen poppins-regular text-black">{item.item.place}</span>
            </div>
            <div className="d-inline-flex align-items-center column-gap-10">
              <img src={distanceImage} alt="time" />
              <span className="fontFifteen poppins-regular text-black">{item.item.areaLimit}</span>
            </div>
          </div>
          <p className="mt60 poppins-regular fontSixteen mt-5">{item.item.details}</p>
        </div>
        <div className={Styles.cardInnerRight}>
          <BroadCastDetailImages images={images} />
        </div>
      </div>
    </div>
  );
};
export default BroadCastDetail;
