import React, { FunctionComponent } from 'react';
import Styles from 'components/modal/Modal.module.scss';
import closeModalIcon from 'assets/svg/close.svg';

interface ModalProps {
  closeDialog: (event: React.FormEvent<HTMLDivElement>) => void;
  type?: string;
  cssClass?: string;
}

const Modal: FunctionComponent<ModalProps> = (props) => {
  const { closeDialog, children, type, cssClass } = props;
  return (
    <>
      <section className={Styles.modalWrapper}>
        <div className={Styles.modalContent}>
          <div className={`${Styles.modalHeader}`}>
            <h4 className={`mb-0 ${Styles.modalTitle}`}>&nbsp;</h4>
            {type === 'type' && (
              <div className="text-blue" onClick={closeDialog} onKeyPress={closeDialog} role="button" tabIndex={0}>
                <img src={closeModalIcon} alt="close" className={Styles.closeIcon} />
              </div>
            )}
          </div>
          <div className={`customScrollBar ${Styles.modalBody} ${cssClass}`}>{children}</div>
        </div>
      </section>
      <div className={Styles.modalBgLayer} onClick={closeDialog} onKeyPress={closeDialog} role="button" tabIndex={0} />
    </>
  );
};
export default Modal;
