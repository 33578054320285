import React, { FunctionComponent } from 'react';
import Select from 'react-select';

interface SelectBoxProps {
  options: any;
  value: any;
  handleChange?: Function;
  placeholder: string;
}

const SelectBox: FunctionComponent<SelectBoxProps> = (props: SelectBoxProps) => {
  const { options, value, handleChange, placeholder } = props;

  const onChange = (selectedValue: any) => {
    if (handleChange) handleChange(selectedValue);
  };

  return (
    <>
      <Select
        onChange={onChange}
        value={value}
        options={options}
        className="selectBoxWrapper"
        placeholder={placeholder}
      />
    </>
  );
};
export default SelectBox;
