import React, { FunctionComponent } from 'react';
import Styles from './RadioButton.module.scss';

interface RadioButtonProps {
  name: string;
  label?: string;
  checked?: boolean;
}

const RadioButton: FunctionComponent<RadioButtonProps> = (props: RadioButtonProps) => {
  const { name, label, checked } = props;
  const id = Math.random().toString(4).substring(1, 4);
  console.log(id);
  return (
    <>
      <div className={Styles.radio}>
        <input id={id} name={name} type="radio" defaultChecked={checked} />
        <label htmlFor={id} className={`m-0 ${Styles.radioLabel}`}>
          {label}
        </label>
      </div>
    </>
  );
};

export default RadioButton;
