import React, { FunctionComponent, useState } from 'react';
import Styles from 'components/newBroadCast/SubscriptionPlan/PlanItem/PlanItem.module.scss';
import mobileOrangeImage from 'assets/svg/phone_orange.svg';
import mobileWhiteImage from 'assets/svg/phone_white.svg';
import deviceOrangeImage from 'assets/svg/devices_orange.svg';
import deviceWhiteImage from 'assets/svg/devices_white.svg';
// import ticketOrangeImage from 'assets/svg/ticket_orange.svg';
// import ticketWhiteImage from 'assets/svg/ticket_white.svg';

interface PlanItemProps {
  selectedPlan: string;
  selectPlan: Function;
}

const PlanItem: FunctionComponent<PlanItemProps> = (props: PlanItemProps) => {
  const { selectedPlan, selectPlan } = props;
  const [subscriptionType, setSubscriptionType] = useState(selectedPlan || '');
  const subscription = (value: string) => {
    setSubscriptionType(value);
    selectPlan(value);
  };
  return (
    <>
      <div
        className={subscriptionType === 'free' ? Styles.selected : ''}
        onClick={() => subscription('free')}
        onKeyPress={() => subscription('free')}
        role="button"
        tabIndex={0}
      >
        <div className={Styles.itemWrapper}>
          <h5 className={`font-weight-bolder mb-3 text-capitalize ${Styles.itemHeading}`}>Free</h5>
          <div className="d-flex align-items-start column-gap-15">
            <img
              src={subscriptionType === 'free' || selectedPlan === 'free' ? mobileWhiteImage : mobileOrangeImage}
              alt="mobile"
              className={Styles.itemImage}
            />
            <p className={`fontFourteen poppins-regular ${Styles.itemDetails}`}>
              Broadcast to 5 people
              <br />
              or less
            </p>
          </div>
        </div>
      </div>
      <div
        className={subscriptionType === 'fivePlus' || selectedPlan === 'fivePlus' ? `${Styles.selected}` : ''}
        onClick={() => subscription('fivePlus')}
        onKeyPress={() => subscription('fivePlus')}
        role="button"
        tabIndex={0}
      >
        <div className={Styles.itemWrapper}>
          <h5 className={`font-weight-bolder mb-3 text-capitalize ${Styles.itemHeading}`}>5+</h5>
          <div className="d-flex align-items-start column-gap-15">
            <img
              src={subscriptionType === 'fivePlus' ? deviceWhiteImage : deviceOrangeImage}
              alt="device"
              className={Styles.itemImage}
            />
            <p className={`fontFourteen poppins-regular ${Styles.itemDetails}`}>
              $10/month
              <br />
              Broadcast to 3000
              <br />
              people or less
            </p>
          </div>
        </div>
      </div>
      {/* <div
        className={subscriptionType === 'entryFee' || selectedPlan === 'entryFee' ? `${Styles.selected}` : ''}
        onClick={() => subscription('entryFee')}
        onKeyPress={() => subscription('entryFee')}
        role="button"
        tabIndex={0}
      >
        <div className={Styles.itemWrapper}>
          <h5 className={`font-weight-bolder mb-3 text-capitalize ${Styles.itemHeading}`}>Entry Fee</h5>
          <div className="d-flex align-items-start column-gap-15">
            <img
              src={subscriptionType === 'entryFee' ? ticketWhiteImage : ticketOrangeImage}
              alt="ticket"
              className={Styles.itemImage}
            />
            <p className={`fontFourteen poppins-regular ${Styles.itemDetails}`}>
              Charge a fee to join your
              <br />
              broadcast ($1 minimum)
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default PlanItem;
