import React, { FunctionComponent } from 'react';
import { LightBox } from 'react-lightbox-pack';
import 'react-lightbox-pack/dist/index.css';

interface LightBoxPackProps {
  toggle: boolean;
  handleChange: Function;
  data: any;
  setSIndex: Function;
  sIndex: Number;
}
const LightBoxPack: FunctionComponent<LightBoxPackProps> = (props: LightBoxPackProps) => {
  const { toggle, handleChange, data, setSIndex, sIndex } = props;
  return (
    <LightBox
      state={toggle}
      event={handleChange}
      data={data}
      imageWidth="60vw"
      imageHeight="70vh"
      thumbnailHeight={50}
      thumbnailWidth={50}
      setImageIndex={setSIndex}
      imageIndex={sIndex}
    />
  );
};

export default LightBoxPack;
