import React, { FunctionComponent, useState } from 'react';
import Styles from 'components/newBroadCast/subscriptionCharges/SubscriptionCharges.module.scss';
import InputField from 'components/textField/InputField';
import Button from 'components/button/Button';
import { BROADCAST_STATUS } from 'store/constants';
import { Link, useHistory } from 'react-router-dom';
import BackButton from 'components/button/backButton/BackButton';

interface SubscriptionChargesProps {
  navigate: Function;
  broadCast: Function;
  selectedBroadcast: any;
}

const SubscriptionCharges: FunctionComponent<SubscriptionChargesProps> = (props: SubscriptionChargesProps) => {
  const { navigate, broadCast, selectedBroadcast } = props;
  const [charges, setCharges] = useState(selectedBroadcast.charges || '');
  const history = useHistory();
  const handleNavigate = () => {
    navigate(BROADCAST_STATUS.BROADCAST_DETAILS);
    broadCast({
      charges,
    });
  };
  const handlePayment = () => {
    history.push('/broadcasting');
  };
  const handleBack = () => {
    navigate(BROADCAST_STATUS.SUBSCRIPTION);
  };
  return (
    <>
      {selectedBroadcast.plan === 'entryFee' && (
        <div className={`${Styles.subscriptionChargesWrapper} ${Styles.entryFeeWrapper}`}>
          <h4 className="poppins-bold font-weight-bolder fontTwenty text-black">
            How much do you want to charge (USD) people to join your broadcast?
          </h4>
          <p className="poppins-regular fontSixteen text-black w-100 text-center mt-3">Minimum is $1</p>
          <InputField
            placeholder="Enter Amount"
            type="text"
            handleChange={setCharges}
            value={charges}
            cssClass={`poppins-regular mb-4 ${Styles.inputStyle}`}
          />
          <div className="mt-60 text-center w-100">
            <Button label="Next" cssClass={Styles.nextBtnWidth} clickHandler={() => handleNavigate()} icon />
          </div>
        </div>
      )}
      {selectedBroadcast.plan === 'fivePlus' && (
        <div className={`${Styles.subscriptionChargesWrapper} ${Styles.fivePlusWrapper}`}>
          <h4 className="fontTwenty font-weight-600 text-center w-100">Subscription</h4>
          <p className="poppins-regular fontFourteen text-black w-100 text-justify mt-3 mb-5">
            During this difficult times SOUNDBIRD is offering the app for free to spread out groups and keep people a
            safe distance apart.
          </p>
          <p className="poppins-regular fontFourteen text-black w-100 text-center mt-3">
            Get a SoundBird subscription to enable:
          </p>
          <h4 className="poppins-bold font-weight-bolder fontTwenty text-black text-center mt-60">
            Unlimited broadcasting
          </h4>
          <div className="mt-60 text-center w-100">
            <Button
              label="Remove limit for 1650.00/month"
              span="for 1 month"
              cssClass={Styles.removeLimitButton}
              clickHandler={() => handlePayment()}
            />
          </div>
          <p className="poppins-regular fontFourteen font-weight-normal text-black w-100 text-justify mt-5">
            Subscription will be charged to your credit card through your iTunes account. Your subscription will
            automatically renew unless cancelled at least 24 hours before the end of the current period. You will not be
            able to cancel the subscription once activated. Manage your subscriptions in Account Settings after purchase
          </p>
          <div className={Styles.chargerFooterLinks}>
            <Link
              to="/termsandconditions"
              className="text-decoration-none
            fontSixteen poppins-regular text-black"
            >
              Terms of Services
            </Link>
            <br />
            <Link
              to="/privacypolicy"
              className="mt-3 d-inline-block text-decoration-none fontSixteen poppins-regular text-black"
            >
              Privacy Policy
            </Link>
          </div>

          <div className="text-center mt-3">
            <BackButton handleBack={handleBack} label="Back" iconClass="fas fa-angle-left" />
          </div>
        </div>
      )}
    </>
  );
};
export default SubscriptionCharges;
