import React from 'react';
import sendIcon from 'assets/svg/send_blue.svg';
import Styles from 'components/chatBox/ChatBox.module.scss';

const ChatBox = () => (
  <div className={Styles.chatBoxWrapper}>
    <div className={Styles.chatBoxHeight}>
      <div className={Styles.chatBoxInner}>
        <img src="https://cdn-icons-png.flaticon.com/512/147/147144.png" className={Styles.userImage} alt="" />
        <div>
          <div className={Styles.userInfoInner}>
            <h5 className={`${Styles.userName} mb-0`}>Lance</h5>
            <div className={Styles.timeActiveWrapper}>
              <span className={Styles.notActive} />
              <span className={Styles.userTime}>0:03</span>
            </div>
          </div>
          <p className={`${Styles.chatDetails} mb-0`}>Hi guys, Welcome!</p>
        </div>
      </div>
      <div className={Styles.chatBoxInner}>
        <img src="https://cdn-icons-png.flaticon.com/512/147/147144.png" className={Styles.userImage} alt="" />
        <div>
          <div className={Styles.userInfoInner}>
            <h5 className={`${Styles.userName} mb-0`}>Lance</h5>
            <div className={Styles.timeActiveWrapper}>
              <span className={Styles.Active} />
              <span className={Styles.userTime}>0:03</span>
            </div>
          </div>
          <p className={`${Styles.chatDetails} mb-0`}>Hi guys, Welcome!</p>
        </div>
      </div>
    </div>
    <div className={Styles.typeMessageArea}>
      <div className={Styles.typeMessageBg}>
        <img
          className={Styles.typeMessageIcon}
          src="https://cdn-icons-png.flaticon.com/512/147/147144.png"
          alt="user icon"
        />
        <input type="text" className={Styles.inputField} placeholder="Type your message" />
      </div>
      <div className={Styles.sendIcon}>
        <img src={sendIcon} alt="send message icon" />
      </div>
    </div>
  </div>
);
export default ChatBox;
