import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import Profile from 'views/profile/Profile';
import Header from 'components/header/Header';
import DashboardLink from 'components/DashboardLink/DashboardLink';
import LeftNavBar from 'components/leftNavBar/LeftNavBar';
import Styles from 'views/profile/landing/MenuLanding.module.scss';
import PrivacyPolicy from 'views/privacyPolicy/PrivacyPolicy';
import TermAndConditions from 'views/termAndConditions/TermAndConditions';
import Subscription from 'views/subscription/Subscription';
import Notifications from 'views/notifications/Notifications';
import DateAndTime from 'views/dateAndTime/DateAndTime';
import { useSelector } from 'react-redux';
import { getUser } from 'store/selector/auth.selector';

const MenuLanding: FunctionComponent = () => {
  const user = useSelector(getUser);
  return (
    <div>
      <Header user={user} />
      <div className="custom-container">
        <DashboardLink iconClass="fas fa-chevron-left" cssClass="mt-40 mb-45" />
        <div className={Styles.menuLandingWrapper}>
          <div className={Styles.leftSide}>
            <LeftNavBar />
          </div>
          <div className={Styles.rightSide}>
            <Switch>
              <Route exact path="/subscription">
                <Subscription />
              </Route>
              <Route exact path="/profile">
                <Profile user={user} />
              </Route>
              <Route exact path="/dateandtime">
                <DateAndTime />
              </Route>
              <Route exact path="/notifications">
                <Notifications />
              </Route>
              <Route exact path="/termsandconditions">
                <TermAndConditions />
              </Route>
              <Route exact path="/privacypolicy">
                <PrivacyPolicy />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuLanding;
