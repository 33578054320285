import React, { FunctionComponent, useState } from 'react';
import Button from 'components/button/Button';
import Styles from 'components/timeFormat/TimeFormat.module.scss';
import RadioButton from 'components/button/radioButton/RadioButton';

interface TimeFormatProps {
  handleClose: Function;
}
// https://we.tl/t-IB57s3FVgx
const TimeFormat: FunctionComponent<TimeFormatProps> = (props: TimeFormatProps) => {
  const { handleClose } = props;
  const [checked] = useState(true);
  const handleDown = () => handleClose();
  return (
    <div className={Styles.timeFormatWrapper}>
      <h4 className="fontTwenty font0weight-bolder text-center w-100">Time Format</h4>
      <div className="d-flex justify-content-between mt-30">
        <div className={Styles.timeFormatInner}>
          <p className="mb-0 fontSixteen poppins-regular text-black">HH:MM</p>
          <span className="fontThirteen poppins-regular input-color">Time is based on a 24 hour system.</span>
        </div>
        <RadioButton name="time_format" />
      </div>
      <div className="d-flex justify-content-between mt-30">
        <div className={Styles.timeFormatInner}>
          <p className="mb-0 fontSixteen poppins-regular text-black">HH:MM XM</p>
          <span className="fontThirteen poppins-regular input-color">
            Time is based on a 12 hour system. AM or PM is given
          </span>
        </div>
        <RadioButton name="time_format" checked={checked} />
      </div>
      <div className="w-100 text-center mt-50">
        <Button cssClass={Styles.btnWidth} label="Done" clickHandler={handleDown} />
      </div>
    </div>
  );
};
export default TimeFormat;
