import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import InputField from 'components/textField/InputField';
import Button from 'components/button/Button';
import Styles from 'views/register//Register.module.scss';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';
import { checkEmail } from 'utils/FormValidation';
import LoginLeftSection from 'components/loginLeftSection/LoginLeftSection';
import { useSelector } from 'react-redux';
import { User } from '../../modules/user/types';
import { getUser } from '../../store/selector/auth.selector';
import EmailConfirmModal from '../../components/EmailConfirmModal/emailConfirmModal';

interface LoginProps {
  isLoading: boolean;
  authError: string;
  loginRequest: Function;
  resetErrorMesage: Function;
  isConfirm: boolean;
}

const Login: FunctionComponent<LoginProps> = (props: LoginProps) => {
  const { isLoading, loginRequest, resetErrorMesage, authError, isConfirm } = props;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(isConfirm);
  const [confirm, setConfirm] = useState<boolean>(false);
  const history = useHistory();

  const userToken: User = useSelector(getUser);
  useEffect(() => {
    if (userToken && userToken.token) {
      history.push('/broadcasting');
    }
    console.log(isShowConfirm);
  });

  useEffect(() => {
    if (isConfirm) setIsShowConfirm(isConfirm);
  }, [isConfirm]);

  useEffect(() => {
    resetErrorMesage();
  }, []);

  const handleLogin = () => {
    if (authError !== '' && !confirm) setConfirm(true);
    console.log('');
    isButtonEnabled();
    if (email.length < 1 || password.length < 1 || emailError.length > 0 || isLoading) {
      return;
    }

    const user = getItemFromLocalStorage('user');
    if (user === null) {
      loginRequest({
        username: email,
        password,
      });
    } else {
      history.go(0);
    }
  };

  const handleKeyDownEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const isButtonEnabled = () => {
    setEmailError(checkEmail(email));
  };

  const handleBlur = (event: React.FormEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    setEmailError(checkEmail(val));
  };
  useEffect(() => {
    if (authError !== '' && !confirm) setConfirm(true);
    else setConfirm(false);
  }, [authError]);
  return (
    <div className={Styles.customContainer}>
      <div className={Styles.leftSectionWrapper}>
        <LoginLeftSection />
      </div>
      <div className={Styles.rightSectionWrapper}>
        <div className={Styles.loginArea}>
          <h4 className={Styles.accountHeading}>Login to your account</h4>
          <p className={`mb-0 ${Styles.profileTitle}`}>Welcome back!</p>
          <div className={`mb-3 ${Styles.formWrapper}`}>
            <div className="form-group">
              <InputField
                errorMessage={emailError}
                blurHandler={handleBlur}
                handleChange={setEmail}
                type="email"
                placeholder="Email Address"
              />
            </div>
            <div className="form-group mb-1">
              <InputField
                handleKeyDown={handleKeyDownEvent}
                handleChange={setPassword}
                type="password"
                placeholder="Password"
              />
            </div>
          </div>
          <h5 style={{ color: 'red' }}>{authError}</h5>
          <p className="mb-0 fontFifteen">
            <Link
              to="/forgotPassword"
              className="text-blue text-decoration-none d-inline-block fontFifteen font-weight-bolder"
            >
              Forgot password?
            </Link>
          </p>
          <div className={`text-center w-100 ${Styles.signUpMarginBLock}`}>
            <Button showLoading={isLoading} clickHandler={handleLogin} label="Log in" />
          </div>
          <p className="fontFifteen font-weight-normal text-black">
            Don’t have an account? &nbsp;
            <Link
              to="/registration"
              className="text-blue text-decoration-none d-inline-block fontFifteen font-weight-bolder"
            >
              CREATE AN ACCOUNT
            </Link>
          </p>
        </div>
      </div>
      {confirm && <EmailConfirmModal handleClose={() => setConfirm(false)} name="" email={email} />}
    </div>
  );
};

export default Login;
