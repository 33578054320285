import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from 'components/button/Button';
import InputField from 'components/textField/InputField';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Styles from 'views/register/Register.module.scss';
import { restorePasswordBegin } from 'store/actions/auth.actions';
import LoginLeftSection from 'components/loginLeftSection/LoginLeftSection';
import { getLoading, getRestoreEmail, getRestoreError } from '../../store/selector/auth.selector';

interface RestorePasswordProps {
  restorePassword: Function;
  isLoading: boolean;
  errorMsg: string;
  restoreEmail: string;
}

const RestorePassword: FunctionComponent<RestorePasswordProps> = (props: RestorePasswordProps) => {
  const { restorePassword, isLoading, errorMsg, restoreEmail } = props;
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [restoreCode, setRestoreCode] = useState<string>('');
  const location: any = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.search) {
      setEmail(new URLSearchParams(location.search).get('email') || '');
      setRestoreCode(new URLSearchParams(location.search).get('code') || '');
      return;
    }
    if (!location.state.email) history.push('/login');
    if (location.state && location.state.email) setEmail(location.state.email);
  }, [location]);
  useEffect(() => {
    if (!isLoading && restoreEmail === email) history.push('/login');
  }, [isLoading]);

  useEffect(() => {
    validatePassword();
  });

  const handleRequest = () => {
    if (confirmPassword.length === 0 || password.length === 0 || passwordError.length > 0 || restoreCode.length === 0) {
      return;
    }
    restorePassword({
      code: restoreCode,
      password,
      email,
    });
  };

  const handleKeyDownEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleRequest();
    }
  };

  const handleBlur = (event: React.FormEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    setPasswordError(checkConfirmPasword(val));
  };

  const checkConfirmPasword = (val?: any) => {
    if (password.length === 0) return '';
    if (val && val === password) return '';
    if (password.length < 6) return 'Password must be 6 characters';
    if (confirmPassword === password) return '';
    return 'Password not match';
  };

  const validatePassword = () => setPasswordError(checkConfirmPasword());

  return (
    <div className={Styles.customContainer}>
      <div className={Styles.leftSectionWrapper}>
        <LoginLeftSection />
      </div>
      <div className={Styles.rightSectionWrapper}>
        <div className={Styles.loginArea}>
          <h4 className={Styles.accountHeading}>Restore your account password</h4>
          <div className={`mb-0 ${Styles.formWrapper}`}>
            <div className="form-group">
              <InputField type="text" placeholder="Email Address" value={email} isDisable />
            </div>
            <div className="form-group">
              <InputField handleChange={setRestoreCode} value={restoreCode} type="text" placeholder="Restore Code" />
            </div>
            <div className="form-group mb-3">
              <InputField handleChange={setPassword} type="password" placeholder="Password" />
            </div>
            <div className="form-group mb-1">
              <InputField
                handleKeyDown={handleKeyDownEvent}
                blurHandler={handleBlur}
                handleChange={setConfirmPassword}
                type="password"
                placeholder="Confirm Password"
              />
            </div>
            <h5 style={{ color: 'red' }}>{passwordError}</h5>
            <h5 style={{ color: 'red' }}>{errorMsg}</h5>
          </div>
          <div className={`text-center ${Styles.signUpMarginBLock}`}>
            <Button showLoading={isLoading} clickHandler={handleRequest} label="Update" />
          </div>
          <p className="fontFifteen font-weight-normal text-black">
            Remember your password? &nbsp;
            <Link
              to="/login"
              className="text-blue text-decoration-none display-inline-block fontFifteen font-weight-bolder"
            >
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  restorePassword: restorePasswordBegin,
};
const mapStateToProps = (state: any) => {
  const isLoading = getLoading(state);
  const errorMsg = getRestoreError(state);
  const restoreEmail = getRestoreEmail(state);
  return { isLoading, errorMsg, restoreEmail };
};
export default connect(mapStateToProps, mapDispatchToProps)(RestorePassword);
