import createSelector from 'utils/reselect';

const broadCastState = (state: any) => {
  const { broadCast } = state;
  return broadCast || {};
};

// eslint-disable-next-line import/prefer-default-export
export const getSelectedBroadCast = createSelector(
  broadCastState,
  (broadCastReducer) => broadCastReducer.get('selectedBroadCast') || null,
);

export const getStreamId = createSelector(broadCastState, (broadCastReducer) => broadCastReducer.get('streamId'));

export const getLoading = createSelector(
  broadCastState,
  (broadCastReducer) => broadCastReducer.get('loading') || false,
);
