import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { getDatabase } from 'firebase/database';

// const firebaseConfig = {
//   apiKey: 'AIzaSyCibwzwDY_ab7tY_XIIM1o52v7VSPumFZQ',
//   authDomain: 'soundfly-64e22.firebaseapp.com',
//   databaseURL: 'https://soundfly-64e22.firebaseio.com',
//   projectId: 'soundfly-64e22',
//   storageBucket: 'soundfly-64e22.appspot.com',
//   messagingSenderId: '82767789938',
//   appId: '1:82767789938:web:903b52ab450ddb684d67eb',
//   measurementId: 'G-RHNN4SBBRD',
// };

const firebaseConfig = {
  apiKey: 'AIzaSyAPGHVRWuFKTTEV4Tx63A65-pXYeVfuNDc',
  authDomain: 'soundbird-bc4a6.firebaseapp.com',
  databaseURL: 'https://soundbird-bc4a6.firebaseio.com',
  projectId: 'soundbird-bc4a6',
  storageBucket: 'gs://soundbird-bc4a6.appspot.com',
  messagingSenderId: '1052903666890',
  appId: '1:1052903666890:web:699d6624f12fa2cc0af033',
  measurementId: 'G-WD5PCSQ2JP',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getDatabase(app);
const reference = ref;
export { db, storage, reference, getDownloadURL, uploadBytesResumable };
