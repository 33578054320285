import { connect } from 'react-redux';
import { getLoginRequest, resetErrorMesage } from 'store/actions/auth.actions';
import { getLoading, getRegistrationConfirm, loginError } from 'store/selector/auth.selector';
import Login from 'views/login/Login';

const mapDispatchToProps = { loginRequest: getLoginRequest, resetErrorMesage };

const mapStateToProps = (state: any) => {
  const isLoading = getLoading(state);
  const authError = loginError(state);
  const isConfirm = getRegistrationConfirm(state);
  return { isLoading, authError, isConfirm };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
