import React, { FunctionComponent, useEffect, useState } from 'react';
import Styles from 'components/newBroadCast/SubscriptionPlan/SubscriptionPlan.module.scss';
import Button from 'components/button/Button';
import BackButton from 'components/button/backButton/BackButton';
import { BROADCAST_STATUS } from 'store/constants';
import PlanItem from './PlanItem/PlanItem';

interface SubscriptionPlanProps {
  cssClass?: string;
  navigate: Function;
  broadCast: Function;
  selectedPlan: string;
  modalClass: Function;
}

const SubscriptionPlan: FunctionComponent<SubscriptionPlanProps> = (props: SubscriptionPlanProps) => {
  const { cssClass, selectedPlan, broadCast, navigate, modalClass } = props;
  const [disabled, setDisabled] = useState(true);
  const navigation = (type: string) => {
    navigate(type);
  };
  const getSelectedPlan = (plan: string) => {
    broadCast({
      plan,
    });
  };
  useEffect(() => {
    modalClass(Styles.modalClass);
  }, []);
  useEffect(() => {
    if (selectedPlan) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedPlan]);

  return (
    <div className={`${cssClass}`}>
      <h4 className="fontTwenty font-weight-600 text-center w-100">Choose subscription</h4>
      <div className="mt-30">
        <PlanItem selectedPlan={selectedPlan} selectPlan={(e: string) => getSelectedPlan(e)} />
      </div>
      <div className="mt-5 d-flex flex-column align-items-center">
        <Button
          label="Next"
          clickHandler={() =>
            navigation(
              selectedPlan === 'free' ? BROADCAST_STATUS.BROADCAST_DETAILS : BROADCAST_STATUS.SUBSCRIPTION_CHARGES,
            )
          }
          disabled={disabled}
          icon
          cssClass={`${selectedPlan ? '' : 'disabledBtn'} ${Styles.nextBtnWidth}`}
        />
        <div className="mt-3">
          <BackButton label="Back" iconClass="fas fa-angle-left" handleBack={() => navigation('type')} />
        </div>
      </div>
    </div>
  );
};
export default SubscriptionPlan;
