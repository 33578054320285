import ACTIONS, { FETCH_STATUS } from 'store/constants';

export const setSelectedBroadCast = (payload: any) => ({
  type: ACTIONS.SET_SELECTED_BROADCAST,
  payload,
});

export const createJoinStream = {
  pending: {
    type: ACTIONS.JOIN_STREAM.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.JOIN_STREAM.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.JOIN_STREAM.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
