import moment from 'moment';

export const formateMonthDate = (date: string) => {
  if (date === null || date === undefined) return '';
  return moment(date).format('MMMM DD');
};

export const formateDate = (date: string, format: string) => {
  if (date === null || date === undefined) return '';
  return moment(date).format(format);
};
export const isValidFile = (file: File, type: string) => {
  const fileName = file.name;

  const exts = type === 'image' ? ['png', 'jpg', 'jpeg', 'gif'] : ['pdf', 'doc', 'docx'];

  if (fileName) {
    let getExt = fileName.split('.');
    getExt = getExt.reverse();

    if (!exts.includes(getExt[0].toLowerCase())) {
      return type === 'image' ? 'only image files are allowed' : 'only pdf files are allowed';
    }

    if (file.size / 1024 / 1024 > 2) {
      return 'max. 2MB file size allow';
    }

    return '';
  }
  return '';
};

export const getCurrentDate = () => `${moment().format('YYYY-MM-DD')} 00:00:00`;

export const getDistance = (lat1: any, lon1: any, lat2: any, lon2: any) => {
  const long1 = (lon1 * Math.PI) / 180;
  const long2 = (lon2 * Math.PI) / 180;
  const lati1 = (lat1 * Math.PI) / 180;
  const lati2 = (lat2 * Math.PI) / 180;

  const dlon = long2 - long1;
  const dlat = lati2 - lati1;
  const pow1 = Math.sin(dlat / 2) ** 2;
  const pow2 = Math.sin(dlon / 2) ** 2;
  const a = pow1 + Math.cos(lati1) * Math.cos(lati2) * pow2;
  const c = 2 * Math.asin(Math.sqrt(a));
  const r = 6371;
  return (c * r).toFixed(1);
};

export const isActiveDate = (d: string) => {
  const currentDate = `${moment().format('YYYY-MM-DD')} 00:00:00`;
  return moment(d).isSameOrAfter(currentDate, 'day');
};

export const isPastDate = (d: string) => {
  const currentDate = `${moment().format('YYYY-MM-DD')} 00:00:00`;
  return moment(d).isBefore(currentDate, 'day');
};

export const getFormattedCurrentDate = (format: string) => moment().format(format);

export const getMinDate = (format: string) => moment().format(format);
export const getMaxDate = (format: string) => moment().add(90, 'days').format(format);

export const getAge = (dob?: string): string => {
  if (dob) {
    const age = moment().diff(moment(dob), 'years').toString();
    return `${age} years`;
  }
  return '';
};

export const getFileName = (file: string) => {
  if (file) {
    return file.split('/').pop();
  }
  return 'file';
};

export const defaultPagination = () => ({
  limit: 10,
  nextPage: 1,
  page: 1,
  prevPage: -1,
  totalPages: 1,
});

export const getEmptyRestaurant = () => ({
  id: '-1',
  name: '',
  description: '',
  picture: '',
  restaurant_logo: '',
  paymentQRCode: false,
  orderQRCode: true,
  customerMustPay: 1,
  QRCodeSize: '50',
  noOfTables: 0,
  printTableOnQrCode: true,
});

export const formatePrice = (val: string) => `$ ${val}`;

export const imagePath = (fileName: string) => `https://mashghol.com/yalla_apis/public/uploads/images/${fileName}`;

export const calculateDistance = (lat1: any, lon1: any, lat2: any, lon2: any) => {
  const R = 6371; // km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const latRad1 = toRad(lat1);
  const latRad2 = toRad(lat2);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(latRad1) * Math.cos(latRad2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return Math.floor(d);
};

export const setTime = (startTime: any) => {
  let dateDiff = (startTime - new Date().getTime()) / 1000;
  let countdownTimerText = '';
  // if (Math.abs(dateDiff) <= 60 * 60) {
  //   const timer = setInterval(() => {
  //     dateDiff = (startTime - new Date().getTime()) / 1000;
  //     if (dateDiff <= 0) {
  //       clearInterval(timer);
  //       return;
  //     }
  //     if (dateDiff < 0) {
  //       countdownTimerText = '-';
  //       dateDiff *= -1;
  //     }
  //
  //     const ss = Math.floor(dateDiff % 60);
  //     dateDiff /= 60;
  //     const mm = Math.floor(dateDiff % 60);
  //     countdownTimerText = `${mm} M ${ss} S`;
  //   }, 1000);
  // } else {
  if (dateDiff < 0) {
    dateDiff *= -1;
  }
  dateDiff /= 60;
  const mm = Math.floor(dateDiff % 60);
  dateDiff /= 60;
  const hh = Math.floor(dateDiff % 24);
  dateDiff /= 24;
  const dd = Math.floor(dateDiff);
  if (dd > 0) countdownTimerText = `${dd} D ${hh} H`;
  else countdownTimerText = `${hh} H ${mm} M`;
  return countdownTimerText;
  // }
  // return countdownTimerText;
};

export const getDateTime = (date: any) => {
  const getHours = date.getHours();
  const getMinutes = date.getMinutes();
  const ampm = getHours >= 12 ? 'PM' : 'AM';
  const hours = getHours % 12;
  const newHours = hours || 12; // the hour "0" should be "12"
  const minutes = getMinutes < 10 ? `0${getMinutes}` : getMinutes;
  const strTime = `${newHours}:${minutes}${ampm}`;
  // return `${date.getDate()}/${new Intl.DateTimeFormat('en', { month: 'short' }).format(
  //   date,
  // )}/${date.getFullYear()} - ${strTime}`;
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} - ${strTime}`;
};

export const toRad = (value: any) => (value * Math.PI) / 180;

export const getErrorMessage = (error: any) => {
  const err = JSON.parse(error);
  if (err.nonFieldErrors) {
    return err.nonFieldErrors[0];
  }
  if (err.email) {
    return err.email[0];
  }
  return '';
};

export const broadCastStorage = {
  save: (obj: any) => localStorage.setItem('broadCast', JSON.stringify(obj)),
  get: () => {
    const obj = localStorage.getItem('broadCast') || null;
    if (obj) return JSON.parse(obj);
    return null;
  },
  clear: () => localStorage.removeItem('broadCast'),
};
