import React, { FunctionComponent, useEffect, useState } from 'react';
import Styles from 'components/newBroadCast/scheduleBroadCast/ScheduleBroadCast.module.scss';
import InputField from 'components/textField/InputField';
import TextArea from 'components/textArea/TextArea';
import SelectBox from 'components/selectBox/SelectBox';
import DateTimeField from 'components/dateTimeField/DateTimeField';
import locationImage from 'assets/svg/location.svg';
import plusIcon from 'assets/svg/add image.svg';
import Button from 'components/button/Button';
import BackButton from 'components/button/backButton/BackButton';
import { useSelector } from 'react-redux';
import { ScheduledBroadCast, ILocation } from 'modules/types/types';
import { User } from 'modules/user/types';
import { useHistory } from 'react-router';
import { getUser } from '../../../store/selector/auth.selector';
import { uploadBroadcastImage } from '../../../store/services/firebase-broadcast';

interface IScheduleBroadCastProps {
  closeDialog: Function;
  createSchedule: Function;
  location: ILocation;
}
const ScheduleBroadCast: FunctionComponent<IScheduleBroadCastProps> = (props) => {
  const { closeDialog, location, createSchedule } = props;
  const history = useHistory();
  const [startTime, setStartTime] = useState<any>(new Date());
  const [imageArray, setImageArray] = useState<any>([]);
  const [title, setTitle] = useState('');
  const [limit, setLimit] = useState<any>('');
  const [place, setPlace] = useState('');
  const [description] = useState('');
  const options = [
    { value: 'Public (unlimited)', label: 'Public (unlimited)' },
    { value: '100 m', label: '100 m' },
    { value: '1.0 km', label: '1.0 km' },
    { value: '10 km', label: '10 km' },
  ];
  const user: User = useSelector(getUser);
  const [broadCast, setBroadCast] = useState<ScheduledBroadCast>({
    admin: {
      uid: user.uid || '',
      name: user.fullname || '',
      avatar: user.avatar || '',
    },
    areaLimit: '',
    details: '',
    location,
    place: '',
    startTime: '',
    channelName: '',
  });

  const handleSave = () => {
    if (title !== '' && limit.value !== '' && place !== '') {
      updateObject({ areaLimit: limit.value, details: description, place, startTime, channelName: title });
    }
  };

  const updateObject = (obj: object) => {
    setBroadCast({
      ...broadCast,
      ...obj,
    });
  };
  const changeImage = (file: any) => {
    const path = URL.createObjectURL(file.target.files[0]);
    console.log(uploadBroadcastImage(broadCast, 0, file.target.files[0]).then((e) => console.log('Response : ', e)));
    setImageArray([...imageArray, path]);
  };

  const handleRemove = (index: any) => {
    imageArray.splice(index, 1);
    setImageArray([...imageArray]);
  };
  useEffect(() => {
    console.log(imageArray);
  }, [imageArray]);

  const handleClose = () => closeDialog(false);

  const disable = () => {
    if (title !== '' && limit.value !== '' && place !== '') {
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (title && limit.value && place && startTime) {
      createSchedule(broadCast);
      history.push('/');
    }
  }, [broadCast]);
  return (
    <div className={`w-100 ${Styles.scheduleWrapper}`}>
      <h4 className="w-100 text-center poppins-bold font-weight-bolder fontTwenty text-black ">Schedule Broadcast</h4>
      <div className={`mt-30 ${Styles.scheduleForm}`}>
        <div className={Styles.scheduleFormLeft}>
          <div className="form-group">
            <InputField
              cssClass="bg-grey poppins-regular"
              placeholder="Broadcast Title"
              type="text"
              inputChangeHandler={(e: any) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <TextArea placeholder="Description" />
          </div>
          <div className="form-group">
            <SelectBox placeholder="Area Limit" options={options} value={limit} handleChange={setLimit} />
          </div>
          <div className="form-group position-relative">
            <InputField
              cssClass="bg-grey"
              iconClass={Styles.iconWidth}
              placeholder="Location (full address optional)"
              type="text"
              icon={locationImage}
              inputChangeHandler={(e: any) => setPlace(e.target.value)}
            />
          </div>
          <div className="form-group mb-0 scheduleCmWrapper">
            <DateTimeField value={startTime} onChange={setStartTime} />
          </div>
        </div>
        <div className={`${Styles.scheduleFormRight} customScrollBar`}>
          <h4 className="fontSixteen poppins-bold text-black w-100 text-center mb-0">Add Images</h4>
          <div className={Styles.addImagesWrapper}>
            {imageArray.map((item: any, index: any) => (
              <div className={`${Styles.addFiles}`} key={Math.random()}>
                <img src={item} alt="add images" className={`img-fluid ${Styles.images}`} />
                <div
                  onClick={() => handleRemove(index)}
                  onKeyPress={() => handleRemove(index)}
                  role="button"
                  tabIndex={0}
                  className={Styles.removeImagesWrapper}
                >
                  <span className={`mb-0 ${Styles.removeImages}`}>Remove</span>
                </div>
              </div>
            ))}
            <div className={`bg-grey ${Styles.addFiles}`}>
              <input type="file" onChange={(e) => changeImage(e)} />
              <img src={plusIcon} alt="plus icon" className={Styles.plusIcon} />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center flex-column mt-50">
        <Button clickHandler={() => handleSave()} label="Save" cssClass={Styles.saveBtnWidth} disabled={disable()} />
        <div className="mt-3">
          <BackButton handleBack={handleClose} label="Back" iconClass="fas fa-angle-left" />
        </div>
      </div>
    </div>
  );
};
export default ScheduleBroadCast;
