import React, { FunctionComponent } from 'react';
import Styles from 'views/subscription/Subscription.module.scss';
import SubscriptionItem from 'components/settings/Subscriptions/SubscriptionItem';
import Button from 'components/button/Button';

const Subscription: FunctionComponent = () => {
  const handleCancel = () => {
    console.log('handle Cancel');
  };
  const handleChanges = () => {
    console.log('handle Changes');
  };
  return (
    <>
      <div className="borderStyle">
        <h6 className="fontSixteen text-black poppins-bold mb-4">Choose from the following options:</h6>
        <SubscriptionItem />
      </div>
      <div className={Styles.btnWrapper}>
        <Button label="Cancel" cssClass={Styles.cancelBtn} clickHandler={handleCancel} />
        <Button label="Save Changes" cssClass={`${Styles.saveBtn}`} clickHandler={handleChanges} />
      </div>
    </>
  );
};

export default Subscription;
