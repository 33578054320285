import React, { FunctionComponent, useState } from 'react';
import Button from 'components/button/Button';
import Styles from 'components/dateFormat/DateFormat.module.scss';
import RadioButton from 'components/button/radioButton/RadioButton';

interface DateFormatProps {
  handleClose: Function;
}

const DateFormat: FunctionComponent<DateFormatProps> = (props: DateFormatProps) => {
  const { handleClose } = props;

  const handleDown = () => handleClose();
  const [checked] = useState(true);
  return (
    <div className={Styles.dateFormatWrapper}>
      <h4 className="fontTwenty font0weight-bolder text-center w-100">Date Format</h4>
      <div className="d-flex justify-content-between mt-30">
        <div className={Styles.dateFormatInner}>
          <p className="mb-0 fontSixteen poppins-regular text-black">YYYY/MM/DD</p>
          <span className="fontThirteen poppins-regular input-color">eg. 2020/12/25</span>
        </div>
        <RadioButton name="time_format" />
      </div>
      <div className="d-flex justify-content-between mt-30">
        <div className={Styles.dateFormatInner}>
          <p className="mb-0 fontSixteen poppins-regular text-black">MM/DD/YYYY</p>
          <span className="fontThirteen poppins-regular input-color">eg. 12/25/2020</span>
        </div>
        <RadioButton name="time_format" checked={checked} />
      </div>
      <div className="d-flex justify-content-between mt-30">
        <div className={Styles.dateFormatInner}>
          <p className="mb-0 fontSixteen poppins-regular text-black">DD/MM/YYYY</p>
          <span className="fontThirteen poppins-regular input-color">eg. 25/12/2020</span>
        </div>
        <RadioButton name="time_format" />
      </div>
      <div className="d-flex justify-content-between mt-30">
        <div className={Styles.dateFormatInner}>
          <p className="mb-0 fontSixteen poppins-regular text-black">MMM DD, YYYY</p>
          <span className="fontThirteen poppins-regular input-color">eg. Dec 25, 2020</span>
        </div>
        <RadioButton name="time_format" />
      </div>
      <div className="w-100 text-center mt-50">
        <Button cssClass={Styles.btnWidth} label="Done" clickHandler={handleDown} />
      </div>
    </div>
  );
};
export default DateFormat;
