import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import InputField from 'components/textField/InputField';
import Button from 'components/button/Button';
import Styles from 'views/register//Register.module.scss';
import { checkEmail } from 'utils/FormValidation';
import authApi from 'store/services/auth.services';
import LoginLeftSection from 'components/loginLeftSection/LoginLeftSection';
import { useSelector } from 'react-redux';
import { LOADER_STATUS } from '../../store/constants';
import { getUser } from '../../store/selector/auth.selector';
import EmailConfirmModal from '../../components/EmailConfirmModal/emailConfirmModal';

interface RegisterProps {
  registrationRequest: Function;
  isLoading: boolean;
  isConfirm: boolean;
  errorMsg?: string;
  resetRegistration: Function;
}

const Register: FunctionComponent<RegisterProps> = (props: RegisterProps) => {
  const { isLoading, isConfirm, registrationRequest, errorMsg, resetRegistration } = props;
  const user = useSelector(getUser);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [nameError, setNameError] = useState(false);
  const [emailsError, setEmailsError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordLengthError, setPasswordLengthError] = useState(false);
  const [disable, setDisable] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>('');
  const [requiredField, setRequiredField] = useState<string[]>([]);
  const [emailExist, setEmailExist] = useState<boolean>(false);
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(isConfirm);
  const [isCheckLoading, setIsCheckLoading] = useState<number>(LOADER_STATUS.NA);
  const history = useHistory();

  useEffect(() => {
    validateInput();
  });

  useEffect(() => {
    if (user) {
      const { email: userEmail } = user;
      if (userEmail === email && !nameError && !emailsError && !passwordError) setIsShowConfirm(true);
    }
  }, [email, user]);

  useEffect(() => {
    if (isConfirm) setIsShowConfirm(true);
  }, [isConfirm]);

  useEffect(() => {
    if (user && user.token) {
      history.push('/broadcasting');
    }
  }, [user]);

  useEffect(
    () => () => {
      resetRegistration(false);
      // localStorage.removeItem('user');
    },
    [],
  );

  const handleSignUp = () => {
    const errors: string[] = [];
    if (name === '') setNameError(true);
    else setNameError(false);
    if (email === '') setEmailsError(true);
    else setEmailsError(false);
    if (password === '') setPasswordError(true);
    else setPasswordError(false);
    if (password.length < 6) setPasswordLengthError(true);
    else setPasswordLengthError(false);
    if (errors.length > 0) {
      setRequiredField(errors);
      return;
    }

    if (emailExist || isCheckLoading === LOADER_STATUS.NA) return;

    if (disable) return;

    setRequiredField([]);

    registrationRequest({
      fullname: name,
      email,
      password,
    });
  };

  const handleKeyDownEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSignUp();
    }
  };

  const handleBlur = (event: React.FormEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    const error = checkEmail(val);
    setEmailError(error);
    if (error.length === 0 && email.length !== 0) {
      removeFromErrorList('email');
      try {
        setIsCheckLoading(LOADER_STATUS.PENDING);
        authApi.checkEmailExist(email).then((res: any) => {
          setIsCheckLoading(LOADER_STATUS.COMPLETE);
          if (res.status === 'registered') {
            setEmailExist(true);
            setEmailError('Email Already Exist');
          } else setEmailExist(false);
        });
      } catch (err) {
        console.log('err', err);
        setIsCheckLoading(LOADER_STATUS.COMPLETE);
      }
    }
  };

  const removeFromErrorList = (val: string) => {
    if (!val) return;
    setRequiredField(requiredField.filter((x: string) => x.toLowerCase() !== val.toLowerCase()));
  };

  const validateInput = () => {
    const emailMsg = checkEmail(email);
    const isValidInput = emailMsg.length === 0 && emailError.length === 0 && name.length > 0 && password.length > 0;
    setDisable(!isValidInput);
  };

  // const isInError = (fieldName: string) => {
  //   if (requiredField.includes(fieldName)) {
  //     return `${fieldName} is required`;
  //   }
  //   return '';
  // };
  return (
    <>
      <div className={Styles.customContainer}>
        <div className={Styles.leftSectionWrapper}>
          <LoginLeftSection />
        </div>
        <div className={Styles.rightSectionWrapper}>
          <div className={Styles.loginArea}>
            <h4 className={Styles.accountHeading}>Create Account</h4>
            <p className={`mb-0 ${Styles.profileTitle}`}>Create a free profile for broadcasting.</p>
            <div className={Styles.formWrapper}>
              <div className="form-group">
                <InputField handleChange={setName} type="text" placeholder="Full Name" />
                {nameError && <span style={{ color: 'red' }}>Please Enter Username</span>}
              </div>
              <div className="form-group">
                <InputField
                  blurHandler={handleBlur}
                  handleChange={setEmail}
                  type="email"
                  placeholder="Email Address"
                  errorMessage={emailError.length > 0 ? emailError : errorMsg}
                  showLoading={isCheckLoading === LOADER_STATUS.PENDING}
                />
                {emailsError && <span style={{ color: 'red' }}>Please Enter Email</span>}
              </div>
              <div className="form-group">
                <InputField
                  handleKeyDown={handleKeyDownEvent}
                  handleChange={setPassword}
                  type="password"
                  placeholder="Password"
                />
                {passwordError && <span style={{ color: 'red' }}>Please Enter Password</span>}
                {passwordLengthError && <span style={{ color: 'red' }}>Password length should be 6 or more</span>}
              </div>
            </div>
            <p className={`mb-0 fontFifteen ${Styles.signingUpText}`}>
              By signing up, you agree to our &nbsp;
              <a
                href="https://soundbirdapp.com/terms.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue display-inline-block text-decoration-none fontFifteen font-weight-bolder"
              >
                Terms of Services
              </a>
              &nbsp; and &nbsp;
              <br />
              <a
                href="https://soundbirdapp.com/privacy.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue display-inline-block text-decoration-none fontFifteen font-weight-bolder"
              >
                Privacy Policy
              </a>
            </p>
            <div className={`text-center w-100 ${Styles.signUpMarginBLock}`}>
              <Button showLoading={isLoading} clickHandler={handleSignUp} label="Sign Up" />
            </div>
            <p className="fontFifteen font-weight-normal text-black">
              Already have an account? &nbsp;
              <Link
                to="/login"
                className="text-blue display-inline-block text-decoration-none fontFifteen font-weight-bolder"
              >
                LOG IN
              </Link>
            </p>
          </div>
        </div>
      </div>
      {isShowConfirm && <EmailConfirmModal handleClose={() => setIsShowConfirm(false)} name={name} email={email} />}
    </>
  );
};

export default Register;
